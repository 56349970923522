<template>
  <div class="d-flex flex-column my-10" style="width: 100vw" v-if="$t('transfer.tableTitle')!=='transfer.tableTitle'">
    <v-card class="infoCard">
      <v-card-title>{{$t('transfer.tableTitle')}}</v-card-title>
      <v-card-text>
        <v-data-table
          style="background-color: transparent"
          :headers="getHeaders()"
          :items="tableData"
          disable-sort
          class="elevation-0"
          hide-default-footer
        >
        </v-data-table>
        <h3 class="my-4">{{ $t('transfer.exampleTitle') }}</h3>
        <ol>
          <li>{{ $t('transfer.examplePoint1') }}</li>
          <li>{{ $t('transfer.examplePoint2') }}</li>
          <li>{{ $t('transfer.examplePoint3') }}</li>
          <li>{{ $t('transfer.examplePoint4') }}</li>
          <li>{{ $t('transfer.examplePoint5') }}</li>
        </ol>
      </v-card-text>
      <v-row class="mt-1">
        <v-col cols="12" md="12" class="pa-2">
          <v-btn
            dark
            block
            x-large
            class="infoButton"
            color="blue darken-3"
            title="Waves Exchange"
            @click="loginWaves"
          >
            <h3>WAVES</h3>&nbsp;<img src="/img/coins/logo-exchange.svg" alt="waves exchange" />
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text class="subtitle-2 font-weight-thin">
        {{ $t('transfer.hint') }}
      </v-card-text>
    </v-card>
    <div class="subtitle-2 font-weight-thin mt-2 align-self-center"><a class="white--text" :href="$t('home.whitePaper').includes('Белая') ? '/pdf/RusCoin White Paper_040921_eng-converted.pdf' : '/pdf/RusCoin White Paper_040921_ru-converted.pdf'">{{$t('home.whitePaper')}}</a></div>
    <div class="subtitle-2 font-weight-thin mt-2 align-self-center" v-if="$t('home.whitePaper').includes('Белая')"><a class="white--text" href="https://vk.com/ruscoin2020">Vkontakte</a> <a class="white--text" href="https://www.facebook.com/groups/ruscoin">Facebook</a> <a class="white--text" href="https://www.instagram.com/ruscoin20/">Instagram</a> <a class="white--text" href="https://t.me/ruscoin_20">Telegram</a></div>
    <div class="subtitle-2 font-weight-thin mt-2 align-self-center" v-else><a class="white--text" href="https://twitter.com/RusCoin20">Twitter</a> <a class="white--text" href="https://www.linkedin.com/in/ruscoin20/">LinkedIn</a> <a class="white--text" href="https://www.facebook.com/groups/ruscoin.eng/">Facebook</a> <a class="white--text" href="https://www.instagram.com/ruscoin20/">Instagram</a> <a class="white--text" href="https://t.me/RusCoin_Eng">Telegram</a></div>
    <div class="subtitle-2 font-weight-thin mt-2 align-self-center">© 2017-2021 RusCoin</div>
    <v-dialog persistent v-model="showCalculator" maxWidth="520" class="calculator" overlay-opacity="0.7">
      <TransferCalculator @change="changeQuantity" @send="sendQuantity" @close="showCalculator = false" />
    </v-dialog>

    <v-dialog persistent v-model="showCheck" maxWidth="520" class="calculator" overlay-opacity="0.7">
      <TransferCheck @close="showCheck = false" :confirmed="confirmed" :transactionId="transactionId" />
    </v-dialog>
  </div>
</template>

<script>
import { Signer } from '@waves/signer';
import { ProviderWeb } from '@waves.exchange/provider-web';
import TransferCalculator from '../components/TransferCalculator'
import TransferCheck from '../components/TransferCheck'
const crypto = require('@waves/ts-lib-crypto')

export default {
  name: 'Waves',
  components: { TransferCalculator, TransferCheck },
  data: () => ({
    loading: false,
    signer: null,
    quantity: null,
    showCalculator: false,
    showCheck: false,
    confirmed: false,
    transactionId: '',
    headers: [
      {
        text: '',
        align: 'start',
        value: 'value',
        width: '180px',
        sortable: false,
      },
      { text: 'Период стейкинга', value: 'period', sortable: false },
      { text: 'Ставка за 100 дней', value: 'percent' },
      { text: 'Выплата процентов в RusCoin 1 и 15 числа', value: 'benefit' },
      { text: 'Ставка за месяц', value: 'month', sortable: false },
    ],
    tableData: [
      { value: '100 - 999', period: '100', percent: '2%', benefit: '0.3%', month: '0.6%'},
      { value: '1 000 - 9 999', period: '100', percent: '4%', benefit: '0.6%', month: '1.2%'},
      { value: '10 000 - 99 999', period: '100', percent: '6%', benefit: '0.9%', month: '1.9%'},
      { value: '> 100 000', period: '100', percent: '8%', benefit: '1.2%', month: '2.4%'},
    ],
  }),
  computed: {
    address () {return this.$store.state.address},
    rusCoinsBalance () {return this.$store.state.rusCoinsBalance},
    rusCoinAddress () {return this.$store.state.rusCoinAddress},
    expiration () {return this.$store.state.expiration},
    lang () {return this.$i18n.i18next.language},
  },
  methods: {
    getHeaders() {
      this.headers[0].text = this.$t('transfer.tableColumn1')
      this.headers[1].text = this.$t('transfer.tableColumn2')
      this.headers[2].text = this.$t('transfer.tableColumn3')
      this.headers[3].text = this.$t('transfer.tableColumn4')
      this.headers[4].text = this.$t('transfer.tableColumn5')
      return this.headers
    },
    async loginWaves () {
      try {
        if (!this.signer || !this.expiration || this.expiration < Date.now()) {
          this.signer = new Signer()
          this.signer.setProvider(new ProviderWeb())
          const { address, publicKey } = await this.signer.login()
          if (address) {
            this.$store.commit('setAddress', address)
            this.$store.commit('setPublicKey', publicKey)
            this.$store.commit('setExpiration', Date.now() + 1000 * 60 * 59)
          }
        }
        // await this.$store.dispatch('getRusCoinsBalance')
        // await this.$store.dispatch('getWavesBalance')
        const balances = await this.signer.getBalance()
        this.$store.commit('setBalances', balances)
        if (this.address === '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y' || this.address === '') {
          this.$store.dispatch('setLog', {id: this.address, data: {balances}})
          await this.$store.dispatch('getRusCoinsBalance')
          await this.$store.dispatch('getWavesBalance')
        }
        this.showCalculator = true;
      } catch (error) {
        console.log(error)
        if (typeof error === 'string' && error?.indexOf('rejection') !== -1) {
          this.$store.commit('showMessage', { text: 'Окно логина было закрыто', severity: 'warning' });
        } else {
          this.$store.commit('showMessage', { text: 'Не удалось залогиниться на waves.exchange.', severity: 'error' });
        }
        this.signer = null
      }
    },
    changeQuantity(value) {
      this.quantity = value
    },
    sendQuantity(value) {
      this.quantity = value
      this.transferWaves()
    },
    async transferWaves() {
      try {
        const tx = await this.signer.transfer({
          recipient: '3PApebH2ATkYEQEFiyYgM4XUndXg3VXWBJy', // alexey
          // recipient: '3PNCTdLy1X86E4WFQa8diMEcWsy1eokBcBg', // dendroid
          amount: this.quantity * 100000,
          assetId: this.rusCoinAddress,
          attachment: crypto.base58Encode(crypto.stringToBytes('stacking transfer')),
        }).broadcast()

        console.log('tx:', tx)
        if (tx?.id) {
          this.transactionId = tx?.id
          this.$store.dispatch('setTransactionData', { id: tx.id, data: tx })
          this.showCheck = true
          const balances = await this.signer.getBalance()
          this.$store.commit('setBalances', balances)
        }
        this.signer.waitTxConfirm(tx, 1).then(async () => {
          this.confirmed = true
          this.$store.commit('showMessage', { text: 'Ваш перевод успешно записан в сети блокчейн.', severity: 'success' });
          const balances = await this.signer.getBalance()
          this.$store.commit('setBalances', balances)
          // await this.$store.dispatch('getRusCoinsBalance')
          // await this.$store.dispatch('getWavesBalance')
          // Tx have one confirmation
        })
      } catch (error) {
        console.log(typeof error, error?.message, error)
        if (typeof error === 'string' && error?.indexOf('rejection') !== -1) {
          this.$store.commit('showMessage', { text: 'Вы нажали "Reject", для подтвержения перевода надо нажать "Confirm".', severity: 'warning' });
        } else {
          this.$store.commit('showMessage', { text: 'Не удалось сделать перевод на waves.exchange.', severity: 'error' });
        }
      }
    }
  },
  async mounted () {
    document.title = 'RusCoin + Waves Exchange'
    // console.log(this.$i18n.i18next.language)
    // setTimeout(() => this.transactionId = '111', 100)
    // setTimeout(() => this.showCheck = true, 100)
    // setTimeout(() => this.confirmed = true, 3000)
  }
}
</script>

<style lang="scss">
.calculator {
  background-color: #292f3c !important;
}
.v-data-table-header-mobile {
  display: none;
}
</style>
