<template>
  <div class="switcher">
    <v-select
      solo
      class="select-language"
      v-model="selected"
      :items="items"
      @change="setLanguage($event)"
    >
      <template slot="selection" slot-scope="data">
        <div>
          <v-img width="36" :src="getFlag(data.item)"/>
        </div>
      </template>
      <template slot="item" slot-scope="data">
        <div class="ml-3">
          <v-img width="36" :src="getFlag(data.item)"/>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  data() {
    return {
      selected: localStorage.getItem('i18nextLng') || 'en-EN',
      items: [
        'en-EN',
        'ru-RU',
        'de-DE',
        'fr-FR',
        'es-ES',
        'cn-CN'
      ]
    }
  },
  computed: {
  },
  methods: {
    getFlag(lng) {
      return '/img/flags/' + lng.split('-')[0] + '.svg'
    },
    getName(lng) {
      return lng.split('-')[1]
    },
    setLanguage(event) {
      this.$i18n.i18next.changeLanguage(event);
    }
  }
}
</script>

<style lang="scss">
.select-language {
  max-width: 95px;
  margin-top: 24px !important;
  .v-input__slot {
    box-shadow: none !important;
  }
}
.dropdown-fade-enter-active, .dropdown-fade-leave-active {
  transition: all .1s ease-in-out;
}
.dropdown-fade-enter, .dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
</style>
