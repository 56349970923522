<template>
  <div class="align-self-center d-flex flex-column">
    <div class="social-btns" v-if="$t('home.whitePaper').includes('Белая')">
      <a class="btn twitter" target="_blank" href="https://twitter.com/RusCoin20"><i class="fa fa-twitter"></i></a>
      <a class="btn vk" target="_blank" href="https://vk.com/ruscoin2020"><i class="fa fa-vk"></i></a>
      <a class="btn facebook" target="_blank" href="https://www.facebook.com/groups/ruscoin"><i class="fa fa-facebook"></i></a>
      <a class="btn linkedin" target="_blank" href="https://www.linkedin.com/in/ruscoin20/"><i class="fa fa-linkedin-square"></i></a>
      <a class="btn telegram" target="_blank" href="https://t.me/ruscoin_20"><i class="fa fa-telegram"></i></a>
      <a class="btn instagram" target="_blank" href="https://www.instagram.com/alekseybykov1"><i class="fa fa-instagram"></i></a>
    </div>
    <div class="social-btns" v-else>
      <a class="btn twitter" target="_blank" href="https://twitter.com/RusCoin20"><i class="fa fa-twitter"></i></a>
      <a class="btn facebook" target="_blank" href="https://www.facebook.com/groups/ruscoin.eng/"><i class="fa fa-facebook"></i></a>
      <a class="btn linkedin" target="_blank" href="https://www.linkedin.com/in/ruscoin20/"><i class="fa fa-linkedin-square"></i></a>
      <a class="btn telegram" target="_blank" href="https://t.me/RusCoin_Eng"><i class="fa fa-telegram"></i></a>
      <a class="btn instagram" target="_blank" href="https://www.instagram.com/alekseybykov1"><i class="fa fa-instagram"></i></a>
    </div>
    <div class="subtitle-2 font-weight-thin mt-2 align-self-center"><i class="fa fa-book mr-1" aria-hidden="true"></i>
      <a class="white--text" :href="$t('home.whitePaper').includes('Белая') ? '/pdf/RusCoin White Paper_141221_ru.pdf' : '/pdf/RusCoin White Paper_141221_eng.pdf'" target="_blank">
        {{$t('home.whitePaper')}}
      </a>
    </div>
    <div class="subtitle-2 font-weight-thin mt-2 align-self-center">© 2017-2021 RusCoin</div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
.social-btns {
  display: flex;
  flex-wrap: nowrap;
  font-size: 0;
  text-align: center;
  margin: 12px 12px 12px 12px;

  // reset
  * {
    margin: 0;
    padding: 0;
    font-size: inherit;
    color: inherit;
    box-sizing: inherit;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    :before {
      vertical-align: top;
    }
  }
  *:focus {
    outline: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    border: 0;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  .btn,
  .btn:before,
  .btn .fa {
    transition: all 0.40s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
  }
  .btn:before {
    top: 90%;
    left: -110%;
  }
  .btn .fa {
    transform: scale(1.0);
  }
  .btn.facebook:before {
    background-color: #3b5998;
  }
  .btn.facebook .fa {
    color: #3b5998;
  }
  .btn.vk:before {
    background-color: #4C75A3;
  }
  .btn.vk .fa {
    color: #4C75A3;
  }
  .btn.twitter:before {
    background-color: #3cf;
  }
  .btn.twitter .fa {
    color: #3cf;
  }
  .btn.linkedin:before {
    background-color: #0077b5;
  }
  .btn.linkedin .fa {
    color: #0077b5;
  }
  .btn.instagram:before {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  }
  .btn.instagram .fa {
    color: #fd5949;
  }
  .btn.telegram:before {
    background-color: #0088cc;
  }
  .btn.telegram .fa {
    color: #0088cc;
  }
  .btn.skype:before {
    background-color: #00aff0;
  }
  .btn.skype .fa {
    color: #00aff0;
  }
  .btn:focus:before,
  .btn:hover:before {
    top: -10%;
    left: -10%;
  }
  .btn:focus .fa,
  .btn:hover .fa {
    color: #fff;
    transform: scale(1.2);
  }
  .btn {
    display: inline-block;
    background-color: rgba(250, 250, 250, 0.05) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    width: 90px;
    height: 90px;
    line-height: 90px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 28%;
    box-shadow: 0 5px 15px -5px rgba(0,0,0,0.5);
    opacity: 0.99;
    @supports not (backdrop-filter: none) {
      background-color: rgba(39,45,60,0.8) !important;
    }

    @media (max-width: 760px) {
      width: 12vw;
      height: 12vw;
      line-height: 12vw;
    }
  }
  .btn:before {
    content: '';
    width: 120%;
    height: 120%;
    position: absolute;
    transform: rotate(45deg);
  }
  .btn .fa {
    font-size: 38px;
    vertical-align: middle;
  }
}
</style>
