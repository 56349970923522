import Vue from 'vue'
import VueRouter from 'vue-router'
import Table from '../views/Table.vue'
import Home from '../views/Home'
import Binance from '../views/Binance'
import Waves from '../views/Waves'
import BinanceSteps from '../views/BinanceSteps'

Vue.use(VueRouter)


const routes = [
  {
    path: '/table',
    name: 'Table',
    component: Table
  },
  {
    path: '/binance',
    name: 'Binance',
    component: Binance
  },
  {
    path: '/binance/steps',
    name: 'Binance',
    component: BinanceSteps
  },
  {
    path: '/waves',
    name: 'Waves',
    component: Waves
  },
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0,
      behavior: 'smooth', }
  }
})

export default router
