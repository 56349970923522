<template>
  <div class="d-flex flex-column mt-8 mb-4" style="width: 100vw" v-if="$t('home.title') && $t('home.title')!=='home.title'">

    <!-- info -->
    <v-card class="infoCard">
      <v-card-title>{{ $t('home.infoTitle') }}</v-card-title>
      <v-card-text>
        <ol>
          <li v-html="$t('home.infoPoint1')"/>
          <li v-html="$t('home.infoPoint2')"/>
          <li v-html="$t('home.infoPoint3')"/>
          <li v-html="$t('home.infoPoint4')"/>
          <li v-html="$t('home.infoPoint5')"/>
          <li v-html="$t('home.infoPoint6')"/>
          <li v-html="$t('home.infoPoint7')"/>
        </ol>
      </v-card-text>
      <v-row class="mt-1">
        <v-col cols="12" md="6" class="pa-2">
          <v-btn
            dark
            block
            x-large
            class="infoButton"
            color="brown darken-3"
            title="Binance smart change"
            @click="$router.push({ path: '/binance' })"
          >
            <img width="180pt" src="/img/coins/Binance_logo.svg" alt="Binance smart chain" />
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
          <v-btn
            dark
            block
            x-large
            class="infoButton"
            color="blue darken-3"
            title="Waves Exchange"
            @click="$router.push({ path: '/waves' })"
          >
            <img src="/img/coins/logo-exchange.svg" alt="waves exchange" />
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
          <v-btn
            dark
            block
            x-large
            class="infoButton"
            color="black col-6"
            style="text-transform: none; color:white !important;"
            @click="openWindow('https://zhcash.org/partner/6100ddecb582ac8ee73fa8d8', 'ZHCash')"
          >
            <img width="36px" class="mr-2 mt-0" src="/img/coins/zhcash.png" alt="ZHCash" /> ZHCash
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="pa-2">
          <v-btn
            block
            x-large
            class="infoButton"
            color="white col-6"
            @click="openWindow('https://relictumlab.io/auth/registration?ref=RusCoin2828', 'Relictum')"
          >
            <img width="160pt" src="/img/coins/relictum_logo.svg" alt="relictum exchange" />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <!-- twitter -->
<!--    <v-card class="infoCard">
      &lt;!&ndash;      <div data-mc-src="9b879bd9-3829-4b97-bdf3-a639ae94ff26#twitter">loading...</div>&ndash;&gt;
      <v-card-title class="mb-n4">{{$t('home.twitterTitle')}}</v-card-title>
      <iframe :width="$vuetify.breakpoint.width < 780 ? '100%' : '716px'" :height="$vuetify.breakpoint.width < 780 ? '450' : '440'" frameborder="0" src="https://widgets.woxo.tech/9b879bd9-3829-4b97-bdf3-a639ae94ff26#/#mission-control-component-d8852c94-694a-414d-8614-ebd4c8264913"></iframe>
    </v-card>-->

    <!-- ambassadors -->
    <v-card class="infoCard">
      <v-card-title class="mb-n4">{{$t('home.ambaTitle')}}</v-card-title>
      <v-card-text>{{$t('home.ambaText')}}</v-card-text>
      <v-row class="mx-1">
        <v-col
          v-for="(amb, index) in amba"
          :key="amb"
          class="d-flex child-flex"
          md="3"
          sm="4"
        >
          <a :href="'https://app.airnfts.com/nft/' + ambaLinks[index]">
            <v-img
              v-ripple
              :src="`/img/flags/${amb}.svg`"
              aspect-ratio="1.5"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </a>
        </v-col>
      </v-row>
    </v-card>

    <!-- risks -->
    <v-card class="infoCard">
      <v-card-title>{{$t('home.warningTitle')}}</v-card-title>
      <v-card-text>
        {{$t('home.warningText')}}
      </v-card-text>
    </v-card>

    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  name: 'Home',
  components: { Footer, /*twitter*/ },
  data: () => ({
    loading: false,
    amba: ['au', 'cn', 'fr', 'ch', 'de', 'hk', 'ke', 'se', 'sn', 'en', 'pl', 'sk', 'si', 'kr', 'es', 'za', 'tn', 've', 'tr', 'by', 'ge', 'ar', 'no', 'dk' ],
    ambaLinks: ['RusCoin_Australia_Am_1632322589221', 'RusCoin_Canada_Ambas_1632323755904', 'RusCoin_France_Ambas_1632324196450', 'RusCoin_Switzerland_1632324599405', 'RusCoin_Germany_Amba_1632325310069', 'RusCoin_Hong_Kong_Am_1632325685647', 'RusCoin_Kenya_Ambass_1632326057555', 'RusCoin_Sweden_Ambas_1632387311064', 'RusCoin_Senegal_Amba_1632328968773', 'RusCoin_UK_Ambassado_1632332986888', 'RusCoin_Poland_Ambas_1632328681513', 'RusCoin_Slovakia_Amb_1632329771277', 'RusCoin_Slovenia_Amb_1632330023393', 'RusCoin_Korea_Ambass_1632330344961', 'RusCoin_Spain_Ambass_1632330558597', 'RusCoin_SAfrica_Amb_1632330982458', 'RusCoin_Tunisia_1632331247084', 'RusCoin_Argentina_A_1632331623536', 'RusCoin_Turkey_Ambas_1632331822649', 'RusCoin_Belarus_Amba_1632332026992', 'RusCoin_Georgia_Amba_1632332242584', 'RusCoin_Venezuela_Am_1632332447478', 'RusCoin_Denmark_Amba_1632332716885', 'RusCoin_Norway_Ambas_1632326374142', ],
  }),
  computed: {
    address () {return this.$store.state.address},
    rusCoinsBalance () {return this.$store.state.rusCoinsBalance},
    rusCoinAddress () {return this.$store.state.rusCoinAddress},
    expiration () {return this.$store.state.expiration},
    lang () {return this.$i18n.i18next.language},
  },
  methods: {
    openWindow (url, name) {
      window.open(url, name);
    },
  },
  async mounted () {
    document.title = 'RusCoin Home'
    // const twttr = document.createElement('script')
    // twttr.setAttribute("async",true)
    // twttr.setAttribute("data-usrc",true)
    // twttr.setAttribute("src","https://cdn2.woxo.tech/a.js#614abf74c78e8000161e39f4")
    // document.body.appendChild(twttr)
  }
}
</script>

<style lang="scss">
</style>
