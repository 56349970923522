<template>
  <v-card class="calculator">
    <v-card-title class="dialogTitle pt-4">
      Калькулятор стейкинга
      <v-spacer></v-spacer>
      <v-icon class="closeIcon" medium dark @click="$emit('close')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="mt-2">
      <v-subheader class="ml-n4">{{ this.rusCoinsBalance ? 'Какую часть своих монет вы хотите отправить на стейкинг:' : 'Сколько монет вы бы отправили на стейкинг:' }}</v-subheader>
      <v-slider
        class="ml-n2"
        v-model="value"
        thumb-label="always"
        :max="rusCoinsBalance || 10000"
      >
        <template v-slot:thumb-label="{ value }">
          {{ Math.round(value / (rusCoinsBalance || 10000) * 100) + '%' }}
        </template>
      </v-slider>
      <v-subheader class="ml-n4">Ваш доход за 100 дней:</v-subheader>
      <v-slider
        class="ml-n2"
        v-model="value"
        thumb-label="always"
        :max="rusCoinsBalance || 10000"
      >
        <template v-slot:thumb-label="{ value }">
          {{ (value * percent / 100).toFixed(0) }}
        </template>
      </v-slider>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            class="mt-2"
            label="Количество"
            hide-details="true"
            v-model="value"
            :outlined="true"
          >
            <v-avatar size="21" slot="append" class="ml-n1"><v-img src="/img/coins/RusCoinBlue.svg"></v-img></v-avatar>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            readonly
            class="mt-2"
            label="Процент"
            hide-details="true"
            v-model="percent"
            :outlined="true"
          >
            <v-avatar size="21" slot="append" class="ml-n1"><v-icon small>mdi-percent-outline</v-icon></v-avatar>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="1"
          class="d-none d-sm-block"
        >
          <v-icon large class="arrowResult">mdi-arrow-right-circle-outline</v-icon>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            readonly
            class="resultTextfield mt-2"
            label="Доход"
            v-model="result"
            :outlined="true"
            :success-messages="[resultInDollars]"
          >
            <v-avatar size="21" slot="append" class="ml-n1"><v-img src="/img/coins/RusCoinBlue.svg"></v-img></v-avatar>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="dialogButtons">
      <v-alert dense type="error" class="alertMessage my-2" v-if="rusCoinsBalance < 100">
        У вас нет 100 RusСoin на счёте
      </v-alert>
      <v-alert dense type="error" class="alertMessage my-2" v-else-if="wavesBalance < 0.001">
        У вас нет 0.001 waves на счёте
      </v-alert>
      <span v-else>
      </span>
      <v-btn
        :disabled="rusCoinsBalance < 100 || wavesBalance < 0.001"
        dark
        large
        color="primary"
        class="sendButton px-6 my-2 mr-1"
        :loading="loading"
        @click="send"
      >
        Отправить
        <template v-slot:loader>
          ...
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'TransferCalculator',
  watch: {
    value (next) {
      if (next < 100) {
        this.percent = 0
      } else if (next < 1000) {
        this.percent = 2
      } else if (next < 10000) {
        this.percent = 4
      } else if (next < 100000) {
        this.percent = 6
      } else {
        this.percent = 8
      }
      this.result = (this.value * this.percent / 100).toFixed(3)
      if (this.percent) {
        this.resultInDollars = 'От $' + (this.result * 0.5).toFixed(0) + ' до $' + (this.result * 50).toFixed(0)
      } else {
        this.resultInDollars = '0 $'
      }
    },
  },
  data: () => ({
    loading: false,
    value: 0,
    percent: 2,
    result: 0,
    resultInDollars: '0$',
    publicKeyModel: '',
  }),
  computed: {
    address () {
      return this.$store.state.address
    },
    accessKey () {
      return this.$store.state.accessKey
    },
    rusCoinsBalance () {
      return this.$store.state.rusCoinsBalance || 0
    },
    wavesBalance () {
      return this.$store.state.wavesBalance || 0
    },
  },
  methods: {
    send () {
      this.$emit('close')
      this.$emit('send', this.value)
    },
  },
  mounted () {
    this.value = this.rusCoinsBalance || 10000
    console.log(this.value, this.percent, this.result)
  }
}
</script>

<style lang="scss">
.calculator {
  background-color: #292f3c !important;
  .dialogTitle {
    opacity: 0.9;
    border-bottom: 1px solid rgb(58, 64, 80);
  }
  .dialogButtons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(58, 64, 80);

    @media (max-width: 780px) {
      flex-wrap: wrap;
    }

    .alertMessage {
      min-height:44px;
      padding-top:10px;
      opacity: 0.8;
      @media (max-width: 780px) {
        min-width: 100%;
      }
    }
    .sendButton {
      @media (max-width: 780px) {
        min-width: 100%;
      }
    }
  }
  .arrowResult {
    margin-left: -9px;
    margin-top: 18px;
    opacity: 0.7;
  }
  .resultTextfield.success--text {
    color: white !important;
    .v-label {
      color: white !important;
    }
    .v-messages__wrapper {
      color: white !important;
    }
  }
}
</style>
