const copyToClipboard = async (text, successFunction) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      const ta = document.createElement('textarea');
      ta.innerText = this.url;
      document.body.appendChild(ta);
      ta.select();
      document.execCommand('copy');
      ta.remove();
    }
    successFunction && successFunction();
  } catch (error) {
    console.log(error);
  }
}
export default copyToClipboard
