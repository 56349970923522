<template>
  <div class="d-flex flex-column my-5" style="width: 100vw" v-if="$t('home.title')!=='home.title'">

    <!--stake Metamask-->
<!--    <v-card class="bscCard" v-if="metamaskRusCoinAdded || rusCoinsStakingBalance || rusCoinsBalance">
      <v-card-title>{{$t('bsc.stakingTitle')}}</v-card-title>
      <v-card-text v-html="$t('bsc.stakingText')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" v-if="rusCoinsBalance === 0 && rusCoinsStakingBalance > 0">
        {{$t('bsc.stakingDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!isMetamask || !isBsc || rusCoinsBalance >= 2" @click="stakingMetamask()" v-else>
        {{$t('bsc.stakingButton')}}
      </v-btn>
    </v-card>-->

    <v-btn color="yellow darken-3" x-large class="blockButtonBsc mx-auto mt-2" @click="openWindow('https://pancakeswap.finance/swap?outputCurrency=0xb5f8ec2e6190bc79e4a6d64168bd0ba406c31073')">Buy RSC<v-icon size="32" class="my-1 ml-2">mdi-arrow-right-bold-circle-outline</v-icon></v-btn>

    <v-btn color="green" x-large class="blockButtonBsc mx-auto mt-4" to="/binance/steps">{{$t($vuetify.breakpoint.width > 700 ? 'bsc.stepsButton' : 'bsc.stepsButtonShort')}} <v-icon size="32" class="my-1 ml-2">mdi-arrow-right-bold-circle-outline</v-icon></v-btn>

    <v-container grid-list-md class="layoutBsc my-6 nowrap pa-0">
      <v-layout row>
        <v-flex xs6>
          <v-card light class="bscHalfCard mr-2">
            <v-layout row class="layoutBsc mx-auto justify-center">
              <v-card-title class="mt-n1 mb-2 col-12 justify-center">
                <span class="">
                  {{$t($vuetify.breakpoint.width > 600 ? 'bsc.yourWallet' : 'bsc.yourWalletShort')}}
                </span>
              </v-card-title>
              <v-flex row class="justify-center">
                <img height="60" src="/img/coins/RusCoinDark.svg" alt="RSC" class="mr-2" />
                <div class="d-flex flex-column">
                  <span class="balanceBsc">
                    {{this.rusCoinsBalanceFormatted}}
                  </span>
                  <span class="balancePriceBsc">
                    ≈ ${{this.rusCoinsPrice}}
                  </span>
                </div>
              </v-flex>

              <v-card-actions class="mb-2 col-12 justify-center">
                <v-btn dark color="orange" class="mt-5 pa-5" :disabled="!isMetamask" @click="metamaskSendBnb()" v-if="smallBalance">
                  {{$t('bsc.sendBNBButton')}}
                </v-btn>
                <v-btn dark color="orange" class="mt-5 pa-5" :disabled="!isMetamask || smallBalance" @click="metamaskSendRsc()" v-else>
                  {{$t('bsc.stakeButton')}}
                </v-btn>
              </v-card-actions>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6>
          <v-card light class="bscHalfCard mr-2">
            <v-layout row class="layoutBsc mx-auto justify-center">
              <v-card-title class="mt-n1 mb-2 col-12 justify-center">
                <span class="">
                  {{$t($vuetify.breakpoint.width > 600 ? 'bsc.stakeWallet' : 'bsc.stakeWalletShort')}}
                </span>
              </v-card-title>
              <v-flex row class="justify-center">
                <img height="60" src="/img/coins/RusCoinDark.svg" alt="RSC" class="mr-2" />
                <div class="d-flex flex-column">
                  <span class="balanceBsc">
                    {{(rusCoinsStakingBalance < 100) ? rusCoinsStakingBalance.toFixed(1) : Math.round(rusCoinsStakingBalance)}}
                  </span>
                  <span class="balancePriceBsc">
                    ≈ ${{this.rusCoinsStakingPrice}}
                  </span>
                </div>
              </v-flex>

              <v-card-actions class="mb-2 col-12 justify-center">

              </v-card-actions>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card light class="bscHalfCard mt-2">
            <v-layout row class="layoutBsc mx-auto justify-center">
              <v-data-table
                style="background-color: transparent"
                :headers="getHeaders()"
                :items="conditions"
                sort-by="balance"
                class="elevation-0"
                hide-default-footer
              >
<!--                <template v-slot:top>
                  <v-toolbar
                    flat
                    style="background-color: transparent"
                  >
                    <v-toolbar-title>RusCoin Waves stake holders</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>-->
                <template v-slot:footer>
                  <v-flex class="mt-2">
                    <span class="caption" v-html="
                      $t('bsc.info1') + '<br/>' + (
                      smallBalance ?
                        $t('bsc.info2').replace('endDateFormatted', endDateFormatted).replace('endDaysFormatted', endDaysFormatted)
                          :
                        $t('bsc.info2').replace('endDateFormatted', endDateFormatted).replace('endDaysFormatted', endDaysFormatted)
                      ) + '<br/>' + (
                      smallBalance ?
                        $t('bsc.info3').replace('rusCoinsBalance', 2).replace('benefitFormatted', 0.04).replace('benefitPriceBUSD', (rusCoinMarketPrice * 0.04).toFixed())
                          :
                        $t('bsc.info3').replace('rusCoinsBalance', rusCoinsBalanceFormatted).replace('benefitFormatted', benefitFormatted).replace('benefitPriceBUSD', benefitPriceBUSD)
                      )
                    ">
                    </span>
                  </v-flex>
                </template>
                <template v-slot:no-data>

                </template>
              </v-data-table>
            </v-layout>
          </v-card>
        </v-flex>

        <v-flex xs12 class="mt-4">
          <v-card light class="bscHalfCard mt-2">
            <v-card-title class="mt-n3 justify-center">
              {{$t('bsc.diagramDistributeTitle')}}
            </v-card-title>
            <v-layout row class="layoutBsc mx-auto justify-center">
              <v-img src="/img/coins/diagram/diagram.png" v-if="$vuetify.breakpoint.width < 800"></v-img>
              <Diagram v-else />
            </v-layout>
            <span class="caption" v-html="$t('bsc.diagramDistributeHint')"/>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

<!--    <v-dialog persistent v-model="showCheck" maxWidth="520" class="calculator" overlay-opacity="0.7">
    </v-dialog>-->

    <Footer />
  </div>
</template>

<script>
import formatWithCommas from '../utils/formatWithCommas'
import to64 from '../utils/to64'
import moment from 'moment'
import 'moment/locale/ru';
import Diagram from '../components/diagrams/Diagram'
import Footer from '../components/Footer'

export default {
  name: 'Binance',
  components: { Footer, Diagram },
  data: () => ({
    loading: false,
    reloadButton: false,
    hideRusCoinButton: false,
    headers: [
      {
        text: 'Количество',
        align: 'start',
        sortable: false,
        value: 'value',
      },
      { text: 'Срок стейкинга', value: 'days', sortable: false, align: 'center' },
      { text: 'Процент в месяц', value: 'percent', sortable: false, align: 'center' },
      { text: 'Годовой процент (APY)', value: 'apy', sortable: false, align: 'center' },
    ],
    conditions: [
      {value: '2 - 20', days: 100, percent: '0.6%', apy: '7.2%' },
      {value: '20 - 200', days: 100, percent: '1.2%', apy: '14.4%' },
      {value: '200 - 2000', days: 100, percent: '1.9%', apy: '22.8%' },
      {value: '2000 - 20000', days: 100, percent: '2.4%', apy: '28.8%' },
    ],
    // DIAGRAM
    diagramData: {  // passed to <diagram> as its modelData
      nodeDataArray: [
        { key: 1, text: "Alpha", color: "lightblue" },
        { key: 2, text: "Beta", color: "orange" },
        { key: 3, text: "Gamma", color: "lightgreen" },
        { key: 4, text: "Delta", color: "pink" }
      ],
      linkDataArray: [
        { from: 1, to: 2 },
        { from: 1, to: 3 },
        { from: 3, to: 4 }
      ]
    },
    diagramDistribute: null,
    currentNode: null,
  }),
  computed: {
    // METAMASK
    innerWidth () {return window.innerWidth},
    ethereum () {return window.ethereum},
    isMetamask () {return this.ethereum?.isMetaMask},
    isBsc () {return +this.$store.state.metamaskChainId === 56},
    isLoading () {return this.$store.state.metamaskChainId === 0},
    accounts () {return this.$store.state.metamaskAccounts},
    bnbBalance () {return this.$store.state.metamaskBalanceLast},
    rusCoinsBalance () {return this.$store.state.metamaskRusCoinBalanceLast},
    rusCoinsBalanceFormatted () {return (this.rusCoinsBalance < 100) ? this.rusCoinsBalance.toFixed(1) : Math.round(this.rusCoinsBalance)},
    rusCoinMarketPrice () {return 98},
    rusCoinsPrice () {return formatWithCommas((this.rusCoinsBalance < 100) ? this.rusCoinsBalance.toFixed(2) : Math.round(this.rusCoinsBalance) * this.rusCoinMarketPrice)},
    today () {return moment().startOf('day')},
    endDate100 () {return this.today.clone().add(100, 'days')},
    endDate115 () {return this.endDate100.date() >= 15 ? this.endDate100.clone().date(1).add(1, 'months') : this.endDate100.clone().date(15)},
    endDateFormatted () {return this.endDate115.format('Do MMMM')},
    endDaysFormatted () {return this.endDate115.diff(this.today, 'days')},
    benefitFormatted () {return (this.rusCoinsBalance * 0.02).toFixed(2)},
    benefitPriceBUSD () {return (this.rusCoinsBalance * 0.02 * this.rusCoinMarketPrice).toFixed(2)},
    smallBalance () {return this.rusCoinsBalance < 2},
    rusCoinsStakingBalance () {return this.$store.state.metamaskRusCoinStakingBalanceLast},
    rusCoinsStakingPrice () {return formatWithCommas((this.rusCoinsStakingBalance < 100) ? this.rusCoinsStakingBalance.toFixed(2) : Math.round(this.rusCoinsStakingBalance) * this.rusCoinMarketPrice)},
    metamaskRusCoinAdded () {return this.$store.state.metamaskRusCoinAdded},
    metamaskBnbBought () {return this.$store.state.metamaskBnbBought},
    metamaskRusCoinBought () {return this.$store.state.metamaskRusCoinBought},
    canMetamask () {return navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("Firefox") !== -1 || navigator.userAgent.indexOf("Brave") !== -1 || navigator.userAgent.indexOf("Edge") !== -1},
    stepsCompleted () {return (this.isMetamask) + (this.accounts.length > 0) + (this.isBsc) + (this.metamaskRusCoinAdded) + (this.metamaskBnbBought) + (this.metamaskRusCoinBought)},
  },
  methods: {
    openWindow (url) {
      window.open(url, 'transaction')
    },
    reloadWindow () {
      window.location.reload()
    },
    async connectMetamask() {
      try {
        // Request account access
        await this.ethereum.enable();
        return true
      } catch(e) {
        // User denied access
        return false
      }
    },
    async metamaskAddBSC () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      const params = [{
        "chainId": "0x38", // 56 in decimal
        "chainName": "Binance Smart Chain",
        "rpcUrls": [
          "https://bsc-dataseed.binance.org"
        ],
        "nativeCurrency": {
          "name": "Binance Coin",
          "symbol": "BNB",
          "decimals": 18
        },
        "blockExplorerUrls": [
          "https://bscscan.com"
        ]
      }]

      try {
        // console.log(params)
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params,
        })
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskAddRSC () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: '0xb5F8eC2e6190Bc79e4a6D64168Bd0BA406c31073', // The address that the token is at.
              symbol: 'RSC', // A ticker symbol or shorthand, up to 5 chars.
              decimals: 5, // The number of decimals in the token
              image: 'https://ruscoin.vip/img/icons/android-chrome-192x192.png', // A string url of the token logo
            },
          },
        });
        // console.log(wasAdded, this.metamaskRusCoinAdded)
        if (wasAdded) {
          // this.hideRusCoinButton = true
          this.$store.commit('setMetamaskRusCoinAdded', true);
        }
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskSendBnb () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        const wasSent = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [{
            nonce: '0x00', // ignored by MetaMask
            gasPrice: '12a05f200', // 5 gwei.
            gas: '0xc350', // customizable by user during MetaMask confirmation.
            to: '0xb5F8eC2e6190Bc79e4a6D64168Bd0BA406c31073', // Required except during contract publications.
            from: this.accounts[0], // must match user's active address.
            value: '0x2c68af0bb140000', // Only required to send ether to the recipient from the initiating external account.
            chainId: '0x38',
          }],
        });
        if (wasSent) {
          this.hideRusCoinButton = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskSendRuscoin () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        const wasSent = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [{
            nonce: '0x00', // ignored by MetaMask
            gasPrice: '12a05f200', // 5 gwei.
            gas: '0xc350', // customizable by user during MetaMask confirmation.
            to: '0xb5F8eC2e6190Bc79e4a6D64168Bd0BA406c31073', // Required except during contract publications.
            from: this.accounts[0], // must match user's active address.
            value: '0x2c68af0bb140000', // Only required to send ether to the recipient from the initiating external account.
            chainId: '0x38',
          }],
        });
        if (wasSent) {
          this.hideRusCoinButton = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskSendRsc () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        // const accounts = await this.ethereum.request({ method: 'eth_accounts' });
        const data = '0xa9059cbb' + '00000000000000000000000085CA6D97b078128fbA18a36292473985A83fBb58' + to64(this.rusCoinsBalance * 100000)
        // console.log(data)
        const pay = await this.ethereum.request({
          method: 'eth_sendTransaction',
          params: [{
            from: this.accounts[0],
            // from: '0x0000000000000000000000000000000000000000',
            data,
            to: '0xb5f8ec2e6190bc79e4a6d64168bd0ba406c31073'
          }]
        })
        console.log(pay)
      } catch (error) {
        console.log(error)
      }
    },
    getHeaders() {
      this.headers[0].text = this.$t('bsc.tableColumn1')
      this.headers[1].text = this.$t('bsc.tableColumn2')
      this.headers[2].text = this.$t('bsc.tableColumn3')
      this.headers[3].text = this.$t('bsc.tableColumn4')
      moment.locale(this.$i18n?.i18next.language);
      return this.headers
    },
  },
  async mounted () {
    document.title = 'RusCoin + Binance Smart Chain'
  }
}
</script>

<style lang="scss">
.blockButtonBsc {
  width: 100%;
  max-width: 780px;
}
.layoutBsc {
  width: 100%;
  max-width: 780px;
}
.balanceBsc {
  font-size: 46px;
  font-weight: 700;
  color: #f7b82e;
  margin-top: -10px;
}
.balancePriceBsc {
  font-size: 18px;
  font-weight: 700;
  color: rgba(60,60,67,.6);
  margin-top: -13px;
}
.bscHeader {
  margin: 4px auto;
  background: transparent;
  text-align: center;
  @media (max-width: 780px) {
    margin: 10px 5px;
    padding: 5px 0;
  }
}
.bscHalfCard {
  height: 100%;
  padding: 22px;
  border-radius: 28px !important;
  background-color: #ffffff !important;
  @media (max-width: 780px) {
    margin: 10px 5px;
    padding: 15px 10px;
  }
}
</style>
