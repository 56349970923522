<template>
  <v-card class="check">
    <v-card-title class="dialogTitle pt-4">
      Перевод отправлен успешно
      <v-spacer></v-spacer>
      <v-icon class="closeIcon" medium dark @click="$emit('close')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="mt-2 d-flex flex-column justify-start">
      <span>{{ confirmed ? 'Ваш перевод подтверждён в сети блокчейн.' : 'Ваш перевод отправлен в сеть блокчейн, ждём подтверждения.' }}</span>
      <span>Детали перевода тут:</span>
      <span class="mx-auto my-4">
        <v-btn
          elevation="10"
          class="animatedButton animate__animated animate__bounceIn"
          fab
          dark
          width="190"
          height="190"
          :color="confirmed ? 'green' : 'primary'"
          @click="openWindow"
        >
          <v-icon size="140" class="arrowResult">mdi-text-box-check</v-icon>
        </v-btn>
      </span>
      <span>Это окно можно закрывать.</span>
    </v-card-text>

    <v-card-actions class="dialogButtons">
      <v-btn
        dark
        large
        color="secondary"
        class="finishButton px-6 my-2"
        :loading="loading"
        @click="$emit('close')"
      >
        Закрыть
        <template v-slot:loader>
          ...
        </template>
      </v-btn>
      <v-btn
        dark
        large
        color="primary"
        class="copyButton px-6 my-2"
        :loading="loading"
        @click="copyToClipboard"
      >
        {{ $vuetify.breakpoint.width < 700 ? 'Копировать ссылку' : 'Копировать ссылку на операцию' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'TransferCheck',
  props: {
    confirmed: {
      type: Boolean,
      default: false
    },
    transactionId: {
      type: String,
      default: ''
    },
  },
  watch: {
    transactionId () {
    },
    confirmed () {
      setTimeout(() => this.restartAnimation('.animatedButton', 'animate__headShake'), 100)
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    url () {return 'https://wavesexplorer.com/tx/' + this.transactionId}
  },
  methods: {
    openWindow () {
      window.open(this.url, 'transaction');
    },
    async copyToClipboard () {
      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(this.url);
        } else {
          const ta = document.createElement('textarea');
          ta.innerText = this.url;
          document.body.appendChild(ta);
          ta.select();
          document.execCommand('copy');
          ta.remove();
        }
        this.$store.commit('showMessage', { text: 'Ссылка на транзакцию скопирована в буфер обмена', severity: 'success' });
      } catch (error) {
        console.log(error);
      }
    },
    restartAnimation (selector, animation) {
      const element = document.querySelector(selector)
      if (element) {
        element.classList.forEach(c => {c.includes('animate__') && !c.includes('animate__animated') && element.classList.remove(c)})
        setTimeout(() => element.classList.add(animation), 100)
      }
    },
  },
  mounted () {
    this.value = this.rusCoinsBalance
  }
}
</script>

<style lang="scss">
.check {
  background-color: #292f3c !important;
  .dialogTitle {
    opacity: 0.9;
    border-bottom: 1px solid rgb(58, 64, 80);
  }
  .dialogButtons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(58, 64, 80);

    @media (max-width: 780px) {
      flex-wrap: wrap;
    }
    .copyButton, .finishButton {
      @media (max-width: 780px) {
        margin-left: 0 !important;
        min-width: 100%;
      }
    }
  }
}
</style>
