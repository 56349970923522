<template>
  <v-card class="settingsDialog">
    <v-card-text class="pt-4">
      <v-text-field
        class="mt-2"
        label="Private key"
        hide-details="auto"
        :autofocus="true"
        v-model="addressModel"
        :outlined="true"
      ></v-text-field>
      <v-text-field
        class="mt-2"
        label="Public key"
        hide-details="auto"
        v-model="publicKeyModel"
        :outlined="true"
      ></v-text-field>
      <v-sheet class="d-flex justify-end">
        <v-btn
          dark
          color="green"
          class="mt-2"
          :loading="loading"
          :disabled="loading"
          @click="save()"
        >
          Save
          <template v-slot:loader>
            ...
          </template>
        </v-btn>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Settings',
  data: () => ({
    loading: false,
    addressModel: '',
    publicKeyModel: '',
  }),
  computed: {
    address () {
      return this.$store.state.address
    },
    accessKey () {
      return this.$store.state.accessKey
    },
  },
  methods: {
    save () {
      this.$store.commit('setAddress', this.addressModel)
      this.$store.commit('setPublicKey', this.publicKeyModel)
      this.checkKeys();
    },
    checkKeys () {
      if (this.addressModel && this.publicKeyModel) {
        this.$store.dispatch('getWavesBalance')
        this.$store.dispatch('getRusCoinsBalance')
      }
    }
  },
  mounted () {
    this.addressModel = this.address
    this.publicKeyModel = this.accessKey
  }
}
</script>

<style lang="scss">
.settingsDialog {
  background-color: #292f3c !important;
}
</style>
