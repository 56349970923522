<template>
  <div style="width:100%; height:440px"></div>
</template>


<script>
import go from 'gojs';
import SankeyLayout from './SankeyLayout'
var $ = go.GraphObject.make;

// This works because we have overridden the /extensionsTS/tsconfig.json file
// in the options to the loader: 'ts-loader', in the webpack.config.js
// import { GuidedDraggingTool } from 'gojs/extensionsTS/GuidedDraggingTool.js';

export default {
  name: 'Diagram',
  mounted() {
    // console.log(this.$vuetify.breakpoint.width)
    var diagram = $(go.Diagram, this.$el, // the ID of the DIV HTML element
      {
        // allowHorizontalScroll: false,
        allowVerticalScroll: false,
        // initialViewportSpot: go.Spot.Center,
        // allowZoom: false,
        // allowSelect: false,
        // contentAlignment: go.Spot.TopLeft,
        padding: 0,
        fixedBounds: new go.Rect(-150, -10, 1300, 1000),
        minScale: 0.5,
        maxScale: 1,
        scale: this.$vuetify.breakpoint.width > 600 ? 0.55 : 0.5,
        // initialPosition: {x: 100, y : 100},
        initialAutoScale: go.Diagram.UniformToFill,
        "animationManager.isEnabled": true,
        // "ModelChanged": (e) => {
          // if (!e.isTransactionFinished) return;
          // var txn = e.object;  // a Transaction
          // if (txn === null) return;
          // console.log("changed-selection", txn.changes);
        // },
        layout: $(SankeyLayout,
          {
            setsPortSpots: false,  // to allow the "Side" spots on the nodes to take effect
            direction: 0,  // rightwards
            layeringOption: go.LayeredDigraphLayout.LayerOptimalLinkLength,
            packOption: go.LayeredDigraphLayout.PackStraighten || go.LayeredDigraphLayout.PackMedian,
            layerSpacing: 10,  // lots of space between layers, for nicer thick links
            columnSpacing: 1
          })
      });

    diagram.nodeTemplate =
      $(go.Node, go.Panel.Horizontal,
        {
          locationObjectName: "SHAPE",
          locationSpot: go.Spot.Center,
          portSpreading: go.Node.SpreadingPacked,  // rather than the default go.Node.SpreadingEvenly
          click: function (e, obj) { console.log(obj.part.location); }
        },
        $(go.TextBlock,  { font: "bold 12pt Segoe UI, sans-serif", stroke: "black", margin: 5 },
          { name: "LTEXT" },
          new go.Binding("text", "ltext")),
        $(go.Shape,
          {
            name: "SHAPE",
            fill: "#2E8DEF",  // default fill color
            strokeWidth: 0,
            portId: "",
            fromSpot: go.Spot.RightSide,
            toSpot: go.Spot.LeftSide,
            height: 10,
            width: 20
          },
          new go.Binding("fill", "color")
        ),
        $(go.Picture, { source: "/img/coins/diagram/RUSCOIN.png", background: "transparent", margin: 5, width: 50, height: 50 },
          new go.Binding("source", (data) => {
            return `/img/coins/diagram/${data.key.split(' ')[0]}.png`
          }),
          new go.Binding("width", (data) => {
            // const node = diagram.model.findNodeDataForKey(data.key);
            // console.log(data)
            return data.size ?? 50
          }),
          new go.Binding("height", (data) => {
            // const node = diagram.model.findNodeDataForKey(data.key);
            // console.log(data)
            return data.size ?? 50
          })
        ),
        $(go.TextBlock,  { font: "bold Segoe UI, sans-serif", stroke: "black", margin: 2 },
          { name: "TEXT" },
          new go.Binding("text")
        ),
      );

    diagram.linkTemplate =
      $(go.Link, go.Link.Bezier,
        {
          selectionAdornmentTemplate: $(go.Adornment, "Link",
            $(go.Shape,
              { isPanelMain: true, fill: null, stroke: "rgba(0, 0, 255, 0.3)", strokeWidth: 0 })  // use selection object's strokeWidth
          ),
          layerName: "Background",
          fromEndSegmentLength: 180, toEndSegmentLength: 180,
          adjusting: go.Link.End
        },
        $(go.Shape, { strokeWidth: 4, stroke: "rgba(173, 173, 173, 0.25)" },
          new go.Binding("stroke", "", (data) => {
            // console.log(data)
            var nodedata = diagram.model.findNodeDataForKey(data.from);
            var hex = nodedata.color;
            if (hex.charAt(0) == '#') {
              var rgb = parseInt(hex.substr(1, 6), 16);
              var r = rgb >> 16;
              var g = rgb >> 8 & 0xFF;
              var b = rgb & 0xFF;
              var alpha = 0.4;
              if (data.width <= 2) alpha = 1;
              var rgba = "rgba(" + r + "," + g + "," + b + ", " + alpha + ")";
              return rgba;
            }
            return "rgba(173, 173, 173, 0.25)";
          }),
          new go.Binding("strokeWidth", "width"))
      );

    // diagram.startTransaction('new object');
    diagram.model.nodeDataArray = [
        {"key":"RUSCOIN", "text":"RusCoin", "color":"#f6e187", size: 100},
        {"key":"BNB Salary", "text":"Owner's 2%", "color":"#f3ba2f", size: 1},
        {"key":"BNB Salary out", "text":"Owner's profit", "color":"#f3ba2f", size: 1},
        {"key":"BUSD", "text":"BUSD", "color":"#ffba1a"},
        {"key":"BNB", "text":"BNB", "color":"#f3ba2f", size: 75},
        {"key":"BNB Hold", "text":"BNB Hold", "color":"#f3ba2f"},
        {"key":"BNB Staking", "text":"BNB Staking", "color":"#f3ba2f"},
        {"key":"HERO", "text":"HERO Hold", "color":"#5ab8ac"},
        {"key":"TRX", "text":"TRX Hold", "color":"#ff0013"},
        {"key":"PVM", "text":"PVM Hold", "color":"#c62d00"},
        {"key":"CAKE", "text":"CAKE Staking", "color":"#d1884f"},
        {"key":"NEAR", "text":"NEAR", "color":"#473e50"},
        {"key":"BUSD Hold", "text":"BUSD Staking", "color":"#ffba1a"},
      ]
    diagram.model.linkDataArray = [
        {"from":"RUSCOIN", "to":"BNB Salary", "width":1},
        {"from":"BNB Salary", "to":"BNB Salary out", "width":1},
        {"from":"RUSCOIN", "to":"BNB", "width":480},
        {"from":"RUSCOIN", "to":"BUSD", "width":10},
        {"from":"BNB", "to":"BNB Hold", "width":15},
        {"from":"BNB", "to":"BNB Staking", "width":16},
        {"from":"BNB", "to":"HERO", "width":86},
        {"from":"BNB", "to":"TRX", "width":82},
        {"from":"BNB", "to":"PVM", "width":15},
        {"from":"BNB", "to":"CAKE", "width":30},
        {"from":"BNB", "to":"NEAR", "width":244},
        {"from":"BUSD", "to":"BUSD Hold", "width":10},
      ]
    // diagram.commitTransaction('new object');
  }
}
</script>
<style lang="scss">
 canvas {
   outline: none;
 }
</style>
