import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'

import firebase from "firebase/app"
import "firebase/auth"
// import "firebase/messaging"
import "firebase/analytics"
import "firebase/firestore"

var firebaseConfig = {
  apiKey: "AIzaSyDnkdYTOYDTpuWaxcKaHSYmY-AMt8s19rA",
  authDomain: "ruscoin.firebaseapp.com",
  projectId: "ruscoin",
  storageBucket: "ruscoin.appspot.com",
  messagingSenderId: "277905821314",
  appId: "1:277905821314:web:341b55139e1a1615786e9b",
  measurementId: "G-M2NTGT4YNW"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const db = firebase.firestore()

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'rus-coin-client',
  namespaced: true,
  storage: window.localStorage,
  reducer: (state) => ({
    metamaskAccountLast: state.metamaskAccountLast,
    metamaskBalanceLast: state.metamaskBalanceLast,
    metamaskRusCoinBalanceLast: state.metamaskRusCoinBalanceLast,
    metamaskRusCoinStakingBalanceLast: state.metamaskRusCoinStakingBalanceLast,
    metamaskRusCoinAdded: state.metamaskRusCoinAdded,
    metamaskBnbBought: state.metamaskBnbBought,
    metamaskRusCoinBought: state.metamaskRusCoinBought,
    address: state.address,
    // accessKey: state.accessKey,
    // wavesBalance: state.wavesBalance,
    // rusCoinsBalance: state.rusCoinsBalance,
  }),
})

export default new Vuex.Store({
  state: {
    // METAMASK
    metamaskAccountLast: null,
    metamaskBalanceLast: 0,
    metamaskRusCoinBalanceLast: 0,
    metamaskRusCoinStakingBalanceLast: 0,
    metamaskRusCoinAdded: false,
    metamaskBnbBought: false,
    metamaskRusCoinBought: false,
    metamaskChainId: [],
    metamaskAccounts: [],
    metamaskBalance: 0,
    metamaskRusCoinBalance: 0,
    // WAVES
    rusCoinAddress: 'EMdiF8uaySswfCdMxc114rSfzUsAKCtK9d8eSx6ruKP4',
    address: '',
    accessKey: '',
    expiration: 0,
    wavesBalance: '',
    rusCoinsBalance: '',
    // COMMON
    message: {},
  },
  mutations: {
    // METAMASK
    setMetamaskAccountLast(state, { account, balance, rusCoinBalance }) {
      state.metamaskAccountLast = account
      state.metamaskBalanceLast = balance / 10**18
      state.metamaskRusCoinBalanceLast = rusCoinBalance / 10**5
    },
    setMetamaskChainId(state, metamaskChainId) {
      state.metamaskChainId = metamaskChainId
    },
    setMetamaskAccounts(state, metamaskAccounts) {
      state.metamaskAccounts = metamaskAccounts
    },
    setMetamaskRusCoinAdded(state, metamaskRusCoinAdded) {
      state.metamaskRusCoinAdded = metamaskRusCoinAdded
    },
    setMetamaskBnbBought(state, metamaskBnbBought) {
      state.metamaskBnbBought = metamaskBnbBought
    },
    setMetamaskRusCoinBought(state, metamaskRusCoinBought) {
      state.metamaskRusCoinBought = metamaskRusCoinBought
    },

    // WAVES
    setAddress(state, value) {
      state.address = value
    },
    setPublicKey(state, value) {
      state.accessKey = value
    },
    setExpiration(state, value) {
      state.expiration = value
    },
    setBalances(state, value) {
      const ruscoin = value.find(coin => coin.assetId === state.rusCoinAddress)
      const wave = value.find(coin => coin.assetId === 'WAVES')
      state.rusCoinsBalance = ruscoin ? ruscoin.amount / 100000 : 0
      state.wavesBalance = wave ? wave.amount / 100000000 : 0
    },
    setWavesBalance(state, value) {
      state.wavesBalance = value ?? ''
    },
    setRusCoinsBalance(state, value) {
      state.rusCoinsBalance = value ?? ''
    },

    // COMMON
    showMessage(state, value) {
      state.message = value ?? ''
    },
  },
  actions: {
    clearCredentials({ state }) {
      state.address = '';
      state.rusCoinAddress = '';
      state.rusCoinsBalance = '';
      state.wavesBalance = '';
    },
    async getWavesBalance({ state, commit }) {
      const { data } = await axios.get(`https://nodes.waves.exchange/addresses/balance/${state.address}`)
      commit('setWavesBalance', data?.balance / 100000000)
      // console.log(data)
    },
    async getRusCoinsBalance({ state, commit }) {
      const { data } = await axios.get(`https://nodes.waves.exchange/assets/balance/${state.address}/${state.rusCoinAddress}`)
      commit('setRusCoinsBalance', data?.balance / 100000)
      // console.log(data)
    },
    async setTransactionData (store, { id, data }) {
      // console.log(id, data)
      return db.collection("tx").doc(id).set(data);
    },
    async setMonthTransactionData (store, { id, data }) {
      // console.log(id, data)
      return db.collection("bonus-tx").doc(id).set(data);
    },
    async setReturnTransactionData (store, { id, data }) {
      // console.log(id, data)
      return db.collection("return-tx").doc(id).set(data);
    },
    async getTransactionData () {
      // console.log(id, data)
      return db.collection("tx").get();
    },
    async authEmail () {
      const credentials = JSON.parse(localStorage.auth)
      console.log(credentials)
      try {
        const user = await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
        return user
      } catch (error) {
        console.log(error)
        return Promise.reject(error)
      }
    },
    async setLog (store, { id, data }) {
      // console.log('log', id, data)
      return db.collection("log").doc(id).set(data);
    },
  },
  modules: {
  },
  plugins: [
    vuexLocal.plugin
  ]
})
