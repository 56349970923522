
import go from 'gojs';

function SankeyLayout() {
  go.LayeredDigraphLayout.call(this);
}
go.Diagram.inherit(SankeyLayout, go.LayeredDigraphLayout);

// determine the desired height of each node/vertex,
// based on the thicknesses of the connected links;
// actually modify the height of each node's SHAPE
SankeyLayout.prototype.makeNetwork = function(coll) {
  var net = go.LayeredDigraphLayout.prototype.makeNetwork.call(this, coll);
  this.diagram.nodes.each(function(node) {
    // figure out how tall the node's bar should be
    var height = getAutoHeightForNode(node);
    var shape = node.findObject("SHAPE");
    if (shape) shape.height = height;
    var text = node.findObject("TEXT");
    var ltext = node.findObject("LTEXT");
    var font = "bold " + Math.max(12, Math.round(height / 8)) + "pt Segoe UI, sans-serif"
    if (text) text.font = font;
    if (ltext) ltext.font = font;
    // and update the vertex's dimensions accordingly
    var v = net.findVertex(node);
    if (v !== null) {
      node.ensureBounds();
      var r = node.actualBounds;
      v.width = r.width;
      v.height = r.height;
      v.focusY = v.height/2;
    }
  });
  return net;
};

function getAutoHeightForNode(node) {
  var heightIn = 0;
  var it = node.findLinksInto()
  while (it.next()) {
    var link = it.value;
    heightIn += link.computeThickness();
  }
  var heightOut = 0;
  var it = node.findLinksOutOf()
  while (it.next()) {
    var link = it.value;
    heightOut += link.computeThickness();
  }
  var h = Math.max(heightIn, heightOut);
  if (h < 10) h = 10;
  return h;
}

// treat dummy vertexes as having the thickness of the link that they are in
SankeyLayout.prototype.nodeMinColumnSpace = function(v, topleft) {
  if (v.node === null) {
    if (v.edgesCount >= 1) {
      var max = 1;
      var it = v.edges;
      while (it.next()) {
        var edge = it.value;
        if (edge.link != null) {
          var t = edge.link.computeThickness();
          if (t > max) max = t;
          break;
        }
      }
      return Math.max(2, Math.ceil(max / this.columnSpacing));
    }
    return 2;
  }
  return go.LayeredDigraphLayout.prototype.nodeMinColumnSpace.call(this, v, topleft);
}

// treat dummy vertexes as being thicker, so that the Bezier curves are gentler
SankeyLayout.prototype.nodeMinLayerSpace = function(v, topleft) {
  if (v.node === null) return 100;
  return go.LayeredDigraphLayout.prototype.nodeMinLayerSpace.call(this, v, topleft);
}

SankeyLayout.prototype.assignLayers = function() {
  go.LayeredDigraphLayout.prototype.assignLayers.call(this);
  var maxlayer = this.maxLayer;
  // now make sure every vertex with no outputs is maxlayer
  for (var it = this.network.vertexes.iterator; it.next();) {
    var v = it.value;
    // var node = v.node;
    if (v.destinationVertexes.count == 0) {
      v.layer = 0;
    }
    if (v.sourceVertexes.count == 0) {
      v.layer = maxlayer;
    }
  }
  // from now on, the LayeredDigraphLayout will think that the Node is bigger than it really is
  // (other than the ones that are the widest or tallest in their respective layer).
};

SankeyLayout.prototype.commitLayout = function() {
  go.LayeredDigraphLayout.prototype.commitLayout.call(this);
  for (var it = this.network.edges.iterator; it.next();) {
    var link = it.value.link;
    if (link && link.curve === go.Link.Bezier) {
      // depend on Link.adjusting === go.Link.End to fix up the end points of the links
      // without losing the intermediate points of the route as determined by LayeredDigraphLayout
      link.invalidateRoute();
    }
  }
};
// end of SankeyLayout
export default SankeyLayout
