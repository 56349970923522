import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify, {
  iconfont: 'fa'
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,
    themes: {
      dark: {
        primary: '#5a81ea',
        secondary: '#e14b51',
        orange: '#f6851b',
        header: '#19202e',
      }
    }
  }
})
