import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import AnimateCSS from 'animate.css';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import VueI18Next from '@panter/vue-i18next';

Vue.use(VueI18Next);

const translationVersion = '0.2.11';

i18next.use(Backend).use(LanguageDetector).init({
  supportedLngs: ['en-EN', 'ru-RU', 'fr-FR', 'de-DE', 'es-ES', 'cn-CN'],
  fallbackLng: 'en-EN',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  detector: {
    order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
  },
  backend: {
    queryStringParams: { v: translationVersion },
  }
});

const i18n = new VueI18Next(i18next);

Vue.use(AnimateCSS)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
