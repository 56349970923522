<template>
  <v-app>
    <v-app-bar
      app
      color="header"
      dark
    >
      <div class="d-flex align-center" v-if="$t('home.title')!=='home.title'">
        <v-toolbar-title class="d-flex align-center">
          <h3 style="cursor: pointer" :class="(isBinance && 'binance-primary-color') + ' ' + (isWaves && 'waves-primary-color')" @click="$router.push({ path: '/' })">{{ $t('home.title') }}</h3>
          <div v-if="isBinance" class="ml-n5"><v-img width="130pt" height="30pt" src="/img/coins/Binance_logo.svg" alt="Binance smart change" /></div>
          <div v-if="isWaves" class="ml-1"><v-img width="120pt" src="/img/coins/logo-exchange.svg" alt="Waves exchange" /></div>
        </v-toolbar-title>
      </div>

      <v-spacer></v-spacer>
      <div v-if="$t('home.title')!=='home.title'" class="d-none d-sm-block">
        <v-btn
          v-if="metamaskAccounts && metamaskAccounts.length"
          dark
          small
          color="gray lighten-3"
          class="mr-3 mb-1"
          @click="copyMetamaskAddress()"
        >
          {{metamaskAccounts[0].slice(2, 5) + '****' + metamaskAccounts[0].slice(-3)}}
          <v-avatar width="38" class="mr-n3">
            <img
              src="/img/common/Fox.svg"
              alt="MetaMask"
            >
          </v-avatar>
        </v-btn>
        <v-btn
          v-else-if="isMetamask"
          dark
          small
          color="gray lighten-3"
          class="mr-3 mb-1"
          @click="connectMetaMask()"
        >
          {{$t('bsc.connectMetaMask')}}
          <v-avatar width="38" class="mr-n3">
            <img
              src="/img/common/Fox.svg"
              alt="MetaMask"
            >
          </v-avatar>
        </v-btn>
        <v-btn
          v-else-if="canMetamask"
          dark
          small
          color="gray lighten-3"
          class="mr-3 mb-1"
          @click="openMetamaskPage()"
        >
          {{$t('bsc.installMetaMask')}}
          <v-avatar width="38" class="mr-n3">
            <img
              src="/img/common/Fox.svg"
              alt="MetaMask"
            >
          </v-avatar>
        </v-btn>
      </div>
      <LanguageSwitcher/>
      <v-text-field
        filled
        rounded
        dense
        readonly
        v-if="rusCoinsBalance !== ''"
        class="balanceFiled mr-1"
        :value="rusCoinsBalance"
        hide-details="true"
        prepend-inner-icon="mdi-cog"
        background-color="green"
        :style="`max-width:${75 + rusCoinsBalance.toString().length * 9}px`"
      >
        <v-avatar size="24" slot="prepend-inner" class="ml-n2 mr-1"><v-img src="/img/coins/RusCoin.svg"></v-img></v-avatar>
      </v-text-field>

      <v-text-field
        filled
        rounded
        dense
        readonly
        v-if="wavesBalance !== ''"
        class="balanceFiled mt2"
        :value="wavesBalance"
        hide-details="true"
        prepend-inner-icon="mdi-cog"
        background-color="green"
        :style="`max-width:${75 + wavesBalance.toString().length * 9}px`"
      >
        <v-avatar size="24" slot="prepend-inner" class="ml-n2 mr-1"><v-img src="/img/coins/waves.svg"></v-img></v-avatar>
      </v-text-field>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-dialog v-model="showSettings" maxWidth="420">
      <Settings />
    </v-dialog>

    <v-snackbar v-model="showMessage" bottom :timeout="6000" :color="message.severity" @click.native="showMessage = false">
      <span style="margin: auto">{{ message.text }}</span>
    </v-snackbar>

  </v-app>
</template>

<script>

import Settings from './components/Settings'
import LanguageSwitcher from './components/LanguageSwitcher'
import copyToClipboard from './utils/copyToClipboard'
import detectEthereumProvider from '@metamask/detect-provider'

export default {
  name: 'App',
  components: { LanguageSwitcher, Settings },
  watch: {
    message () {
      this.showMessage = !!this.message.text
    },
  },
  data: () => ({
    showMessage: false,
    showSettings: false,
    // wavesIcon: require('/img/coins/waves.svg'),
  }),
  computed: {
    isBinance() {return this.$route.path.includes('/binance')},
    isWaves() {return this.$route.path.includes('/waves')},
    // METAMASK
    ethereum () {return window.ethereum},
    isMetamask () {return this.ethereum?.isMetaMask},
    canMetamask () {return navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("Firefox") !== -1 || navigator.userAgent.indexOf("Brave") !== -1 || navigator.userAgent.indexOf("Edge") !== -1},
    metamaskAccounts () {return this.$store.state.metamaskAccounts},
    bnbBalance () {return this.$store.state.metamaskBalanceLast},
    rscBalance () {return this.$store.state.metamaskRusCoinBalanceLast},
    // WAVES
    wavesBalance () {return this.$store.state.wavesBalance},
    rusCoinsBalance () {return this.$store.state.rusCoinsBalance},
    // COMMON
    message () {return this.$store.state.message},
  },
  methods: {
    openMetamaskPage() {
      window.open('https://metamask.io/download');
    },
    async connectMetaMask() {
      try {
        // Request account access
        await this.ethereum.enable();
        return true
      } catch(e) {
        // User denied access
        return false
      }
    },
    async copyMetamaskAddress() {
      copyToClipboard(this.metamaskAccounts[0], this.$store.commit('showMessage', { text: 'Адрес вашего кошелька скопирован в буфер обмена', severity: 'success' }));
    },
    async getMetamaskInfo() {
      const accounts = await this.ethereum.request({ method: 'eth_accounts' });
      this.$store.commit('setMetamaskAccounts', accounts);
      const chainId = await this.ethereum.request({ method: 'eth_chainId' });
      // console.log(111, chainId)
      this.$store.commit('setMetamaskChainId', chainId);
      if (accounts.length) {
        const balance = await this.ethereum.request({ method: 'eth_getBalance', params: [accounts[0], 'latest']})
        const rusCoinBalance = await this.ethereum.request({ method: 'eth_call', params: [{ from: '0x0000000000000000000000000000000000000000', data: '0x70a08231000000000000000000000000' + accounts[0].slice(2), to: '0xb5f8ec2e6190bc79e4a6d64168bd0ba406c31073' },'latest']} );
        if (+chainId === 56) {
          // remember account and balances for BSC only
          // console.log(111, this.bnbBalance)
          this.$store.commit('setMetamaskAccountLast', { account: accounts[0], balance, rusCoinBalance });
          // console.log(111, this.rscBalance, this.bnbBalance)
          if (this.bnbBalance >= 0.2) {
            this.$store.commit('setMetamaskBnbBought', true);
          }
          if (this.rscBalance > 1) {
            this.$store.commit('setMetamaskRusCoinBought', true);
          }
        }
      }
      // this.$store.commit('setMetamaskChainId', chainId);
      // const isUnlocked = await this.ethereum?._metamask?.isUnlocked();
      this.ethereum.on('chainChanged', (chainId) => {
        console.log('chainChanged', chainId)
        this.getMetamaskInfo()
      });
    }
  },
  async mounted () {
    // metamask:
    // console.log(this.$route.path, this.isMetamask, this.ethereum.isConnected())
    if (this.isMetamask) {
      this.getMetamaskInfo()
    } else {
      const provider = await detectEthereumProvider()
      if (provider) {
        this.$store.commit('showMessage', { text: 'Мобильная версия MetaMask найдена', severity: 'success' })
        window.ethereum = provider
        this.getMetamaskInfo()
      }
    }
    // console.log(this.$i18n.i18next, localStorage.getItem('i18nextLng'))
  }
}
</script>

<style lang="scss">
html ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
html ::-webkit-scrollbar-corner {
  background: #19202e;
}
html ::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #555d6d;
}
html ::-webkit-scrollbar-track {
  border-radius: 0;
}
html {
  width: 100%;
  height: 100%;
  background-color: #11141d;
}
body {
  position: absolute;
  font-family: Ubuntu, 'Open Sans', sans-serif !important;
  min-width: 100%;
  background: url('/img/coins/RusCoinBack.svg') no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
}
#app {
  animation: 2s ease all;
  background: transparent;
}
#app.theme--dark {
  input {
    caret-color: white;
  }
}
.infoCard {
  margin: 20px auto;
  padding: 32px;
  background-color: rgba(250,250,250,0.05) !important;
  backdrop-filter: blur(16px);
  max-width: 780px;
  min-width: 100vw;
  @media (min-width: 780px) {
    min-width: 780px;
  }
  @media (max-width: 780px) {
    margin: 10px 5px;
    padding: 5px 0;
    max-width: calc(100% - 10px);
  }
  @supports not (backdrop-filter: none) {
    background-color: rgba(39,45,60,0.8) !important;
  }
}
.balanceFiled .v-input__slot {
  box-shadow: inset 0 0 8px #000000;
}
// transition
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.binance-primary-color {
  color: #f3ba2f;
}
.binance-secondary-color {
  color: #4e342e;
}
.waves-primary-color {
  color: #ffffff;//#e14b51;
}
.waves-secondary-color {
  color: #5a81ea;
}
</style>
