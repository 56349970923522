const z64 = '0000000000000000000000000000000000000000000000000000000000000000'
export default function to64(value) {
  if (typeof value === 'string') {
    const str = value.replace('0x', '')
    return z64.slice(0, 64 - str.length) + str
  }
  if (typeof value === 'number') {
    // console.log(value)
    const oct = Math.round(value).toString(16)
    return z64.slice(0, 64 - oct.length) + oct
  }
  return z64
}
