<template>
  <div class="d-flex flex-column my-10" style="width: 100vw">
    <v-card class="tableCard">
      <v-data-table
        style="background-color: transparent"
        :headers="headers"
        :items="stackers"
        sort-by="balance"
        class="elevation-0"
        hide-default-footer
        show-select
        v-model="selected"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            style="background-color: transparent"
          >
            <v-toolbar-title>RusCoin Waves stake holders</v-toolbar-title>
            <v-spacer></v-spacer>
<!--            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add stacker
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.name"
                          label="Dessert name"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.calories"
                          label="Calories"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.fat"
                          label="Fat (g)"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.carbs"
                          label="Carbs (g)"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                      >
                        <v-text-field
                          v-model="editedItem.protein"
                          label="Protein (g)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>-->
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            title="Смотреть транзакцию"
            class="mr-2"
            @click="openWindow('https://wavesexplorer.com/tx/' + item.id)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            color="green"
            title="Вернуть деньги за стейкинг"
            @click="loginWaves(returnTransferWaves, item.address, item.value)"
            v-if="isOver(item.endDate)"
          >
            mdi-arrow-left-circle
          </v-icon>
<!--          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>-->
        </template>
<!--        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Reset
          </v-btn>
        </template>-->
      </v-data-table>
      <div class="d-flex justify-space-between mt-6" v-if="!showCheck">
        <v-chip :disabled="total <= 0" :color="total <= 0 ? 'grey darken-2' : 'red darken-4'" class="ml-4 mt-2 px-4">Total: {{total.toFixed(2)}} RSC</v-chip>
        <v-btn
          dark
          color="red darken-4"
          class="mt-2 mr-4"
          :loading="loading"
          :disabled="loading || total <= 0"
          @click="loginWaves()"
        >
          Начислить за {{dateText}}
          <template v-slot:loader>
            ...
          </template>
        </v-btn>
      </div>
    </v-card>

    <v-card class="tableCard">
      <v-data-table
        style="background-color: transparent"
        :headers="headersBSC"
        :items="stackersBSC"
        sort-by="balance"
        class="elevation-0"
        hide-default-footer
        show-select
        v-model="selectedBSC"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            style="background-color: transparent"
          >
            <v-toolbar-title>RusCoin BSC stake holders</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
      <div class="d-flex justify-space-between mt-6" v-if="!showCheckBSC">
        <v-chip :disabled="totalBSC <= 0" :color="totalBSC <= 0 ? 'grey darken-2' : 'yellow darken-4'" class="ml-4 mt-2 px-4">Total: {{totalBSC}} RSC</v-chip>
        <v-btn
          dark
          color="yellow darken-4"
          class="mt-2 mr-4"
          :loading="loading"
          :disabled="loading || totalBSC <= 0"
          @click="payStakingBSC()"
        >
          Начислить за {{dateText}}
          <template v-slot:loader>
            ...
          </template>
        </v-btn>
      </div>
    </v-card>

    <v-dialog persistent v-model="showCheck" maxWidth="520" class="calculator" overlay-opacity="0.7">
      <TransferCheck @close="showCheck = false" :confirmed="confirmed" :transactionId="transactionId" />
    </v-dialog>
  </div>
</template>

<script>

import moment from 'moment';
import { Signer } from '@waves/signer'
import { ProviderWeb } from '@waves.exchange/provider-web'
import TransferCheck from '../components/TransferCheck'
const crypto = require('@waves/ts-lib-crypto')

const today = moment().startOf('day')
const today115 = today.date() >= 15 ? today.clone().date(15) : today.clone().date(1)

function percentWaves(value) {
  if (value >= 99999) {
    return 2.4
  }
  if (value >= 9999) {
    return 1.9
  }
  if (value >= 999) {
    return 1.2
  }
  return 0.6
}

function percentBSC(value) {
  if (value >= 2001) {
    return 2.4
  }
  if (value >= 201) {
    return 1.9
  }
  if (value >= 21) {
    return 1.2
  }
  return 0.6
}

function benefitWaves(start, value) {
  const startMoment = moment(start)
  // console.log(value, today115.diff(startMoment, 'days') > 14 ? 15 : today115.diff(startMoment, 'days'))
  const days = Math.max(0, today115.diff(startMoment, 'days') > 14 ? 15 : today115.diff(startMoment, 'days'))
  const percent = percentWaves(value)
  const benefit = (value * (percent / 200) * (days / 15)).toFixed(2)
  // console.log(benefit, 111)
  const startDate = startMoment.format('Do MMMM')
  const endDate100 = startMoment.clone().add(100, 'days')
  const endDate = endDate100.date() >= 15 ? endDate100.date(1).add(1, 'months').format('Do MMMM') : endDate100.date(15).format('Do MMMM')
  return { startMoment, days, percent, benefit, startDate, endDate }
}

function benefitBsc(start, value) {
  const startMoment = moment(start)
  const days = Math.max(today115.diff(startMoment, 'days') > 14 ? 15 : today115.diff(startMoment, 'days'))
  const percent = percentBSC(value)
  const benefit = (value * (percent / 200) * (days / 15)).toFixed(2)
  const startDate = startMoment.format('Do MMMM')
  const endDate100 = startMoment.clone().add(100, 'days')
  const endDate = endDate100.date() >= 15 ? endDate100.date(1).add(1, 'months').format('Do MMMM') : endDate100.date(15).format('Do MMMM')
  return { startMoment, days, percent, benefit, startDate, endDate }
}

const z64 = '0000000000000000000000000000000000000000000000000000000000000000'
function to64(value) {
  if (typeof value === 'string') {
    const str = value.replace('0x', '')
    return z64.slice(0, 64 - str.length) + str
  }
  if (typeof value === 'number') {
    // console.log(value)
    const oct = Math.round(value).toString(16)
    return z64.slice(0, 64 - oct.length) + oct
  }
  return z64
}
export default {
  name: 'Table',
  components: { TransferCheck },
  data: () => ({
    loading: false,
    signer: null,
    quantity: null,
    showCheck: false,
    showCheckBSC: false,
    confirmed: false,
    transactionId: '',
    selected: [],
    selectedBSC: [],
    pagination: {},
    editedItem: {},
    headers: [
      {
        text: 'Address',
        align: 'start',
        sortable: false,
        value: 'short',
      },
      { text: 'Value', value: 'value' },
      { text: 'Percent', value: 'percent' },
      { text: 'Days', value: 'days' },
      { text: 'Benefit', value: 'benefit' },
      { text: 'Transfer Date', value: 'startDate' },
      { text: 'Return Date', value: 'endDate' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    dateText: 'august + september',// today115.format('Do MMMM'),
    stackers: [
      /*{ address: '3PDt7br5En6SmQ2tZqHt2XKVd1N1tq5pMWe', short: '3PDt7****5pMWe', value: 10000, percent: 6, days: 13, benefit: 10000 * 0.009 * 13/15, startDate: moment(1626885378918).format('Do MMMM'), endDate: moment(1626885378918 + 100*24*60*60*1000).format('Do MMMM'), id: 'GC81nABbBugDQRzJLzWz8JKLF6GNhxiABkkBpzaXCZ4Q'},*/
      /*{ address: '3PCiyvkonysZf65eZjoCAStSX4ZRpXhpP9y', short: '3PCiy****hpP9y', value: 1000, percent: 4, days: 14, benefit: 1000 * 0.006 * 14/15, startDate: moment(1626948503599).format('Do MMMM'), endDate: moment(1626948503599 + 100*24*60*60*1000).format('Do MMMM'), id: 'FHDE71M6Q8PZiX8kDDmLKKzEHmYLpfR2oRasDpCKguxx'},*/
      /*{ address: '3PDt7br5En6SmQ2tZqHt2XKVd1N1tq5pMWe', short: '3PDt7****5pMWe', value: 1000000, percent: 8, days: 8, benefit: 1000000 * 0.012 * 8/15, startMoment: moment(1627729039318), startDate: moment(1627729039318).format('Do MMMM'), endDate: moment(1627729039318 + 100*24*60*60*1000).format('Do MMMM'), id: 'D6KnvnjKiHQJswNGQVkcYn9DeJZmM66DzndFs7rrFuBJ' },*/
      /*{ address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', short: '3PCjR****n7n6y', value: 1000, percent: 4, days: 9, benefit: 1000 * 0.006 * 9/15, startMoment: moment(1627823520666), startDate: moment(1627823520666).format('Do MMMM'), endDate: moment(1627823520666 + 100*24*60*60*1000).format('Do MMMM'), id: 'J9W3s9Trs57ihq1PV1HsYGbmZNEKxU3ut9ec9jAfRAP' },*/
      /*{ address: '3PDt7br5En6SmQ2tZqHt2XKVd1N1tq5pMWe', short: '3PDt7****5pMWe', value: 100000, percent: 2.4, days: 14, benefit: 100000 * 0.012 *14/15, startMoment: moment(1628235302169), startDate: moment(1628235302169).format('Do MMMM'), endDate: moment(1628235302169 + 100*24*60*60*1000).format('Do MMMM'), id: '5i9ELBEyuoPdMr14m3uQqdV5f79QZp2bXqMf3ZxTTD3z' },*/
     /* { address: '3PDt7br5En6SmQ2tZqHt2XKVd1N1tq5pMWe', value: 100000, start: 1631192478649, id: 'HC4L26LXXpT5kxXpD9amvBJyoQqbP7YJuTzB1bxNvPLi' },*/
      /*{ address: '3PJ7LSHqeEA5KE2jGt7gVqjrLRYbJPSV7X5', value: 2030, start: 1631192924767, id: 'J6aJWZh63dAoeyFfFe69qp9ktNZqkape6HzzpWQMt7BJ' },
      { address: '3PDt7br5En6SmQ2tZqHt2XKVd1N1tq5pMWe', value: 757478, start: 1632892045092, id: 'EFf645w1R7WNq67UQjQUX2tLBABWCdJAP5ZhwfSKoCPj' },*/
     /* { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1500, start: 1636998996660,  id: '2QcM4oKmNYeJW6V4AsoEvkprVt33CcgbVofjHq6zWEhp' },*/
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1700, start: 1648734671000,  id: '7Q4C1aMqonaihxcNwd9m9hCdvy8ujBCXG72yxGG3ixWK' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1700, start: 1648734671000,  id: '7Q4C1aMqonaihxcNwd9m9hCdvy8ujBCXG72yxGG3ixWK1' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1700, start: 1648734671000,  id: '7Q4C1aMqonaihxcNwd9m9hCdvy8ujBCXG72yxGG3ixWK2' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1700, start: 1648734671000,  id: '7Q4C1aMqonaihxcNwd9m9hCdvy8ujBCXG72yxGG3ixWK3' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1933, start: 1657696380000,  id: 'EtKcQuMLphg9G4Z51HCJEiUB94SrFwFA9No2XgvW7Kyj' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1933, start: 1657696380000,  id: 'EtKcQuMLphg9G4Z51HCJEiUB94SrFwFA9No2XgvW7Kyj1' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1933, start: 1657696380000,  id: 'EtKcQuMLphg9G4Z51HCJEiUB94SrFwFA9No2XgvW7Kyj2' },
      { address: '3PCjRcYgiugGpXmSs5bYFqiHnneyn7n7n6y', value: 1933, start: 1657696380000,  id: 'EtKcQuMLphg9G4Z51HCJEiUB94SrFwFA9No2XgvW7Kyj3' },
    ].map(item => ({
      short: item.address.slice(0, 3) + '****' + item.address.slice(-3),
      ...(benefitWaves(item.start, item.value)),
      ...item,
    })),
    headersBSC: [
      {
        text: 'Address',
        align: 'start',
        sortable: false,
        value: 'short',
      },
      { text: 'Value', value: 'value' },
      { text: 'Percent', value: 'percent' },
      { text: 'Days', value: 'days' },
      { text: 'Benefit', value: 'benefit' },
      { text: 'Transfer Date', value: 'startDate' },
      { text: 'Return Date', value: 'endDate' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    stackersBSC: [
     /* { address: '0x630C3415946c72Fb6dF78447Cc6a0539691D4ccc', value: 21, start: 1636611742000, id: '5i9ELBEyuoPdMr14m3uQqdV5f79QZp2bXqMf3ZxTTD3z' },
      { address: '0x9CD38963E56fD92cdcDE2a2E71Ae947ee19f702e', value: 5, start: 1636611742000, id: '4i9ELBEyuoPdMr14m3uQqdV5f79QZp2bXqMf3ZxTTD3z' },*/
    ].map(item => ({
      short: item.address.slice(0, 5) + '****' + item.address.slice(-3),
      ...(benefitBsc(item.start, item.value)),
      ...item,
    })),
    tx: [],
  }),
  computed: {
    ethereum () {return window.ethereum},
    isMetamask () {return this.ethereum?.isMetaMask},
    isBsc () {return +this.$store.state.metamaskChainId === 56},
    isLoading () {return this.$store.state.metamaskChainId === 0},
    total() {return this.stackers.filter((item) => this.selected.includes(item)).map(item => item.benefit).reduce((a, b) => a + +b, 0)},
    totalBSC() {return this.stackersBSC.filter((item) => this.selectedBSC.includes(item)).map(item => item.benefit).reduce((a, b) => a + +b, 0)},
    rusCoinsBalance () {return this.$store.state.rusCoinsBalance},
    rusCoinAddress () {return this.$store.state.rusCoinAddress},
    expiration () {return this.$store.state.expiration},
  },
  methods: {
    isOver (value) {
       // console.log(moment().add(-100, 'days').format(), value.format())
       // console.log(moment().add(-100, 'days').diff(value)/1000/60/60)
      // return moment().add(-99, 'days').isAfter(value)
      return today.isAfter(moment(value, 'Do MMMM'))
    },
    openWindow (url) {
      window.open(url, 'transaction');
    },
    async loginWaves (transferFunc, transferId, transferValue) {
      try {
        if (!this.signer || !this.expiration || this.expiration < Date.now()) {
          this.signer = new Signer()
          this.signer.setProvider(new ProviderWeb())
          const { address, publicKey } = await this.signer.login()
          if (address) {
            this.$store.commit('setAddress', address)
            this.$store.commit('setPublicKey', publicKey)
            this.$store.commit('setExpiration', Date.now() + 1000 * 60 * 59)
          }
        }
        // await this.$store.dispatch('getRusCoinsBalance')
        // await this.$store.dispatch('getWavesBalance')
        const balances = await this.signer.getBalance()
        console.log(111, balances)
        this.$store.commit('setBalances', balances)
        if (transferFunc) {
          transferFunc(transferId, transferValue)
        } else {
          this.massTransferWaves();
        }
      } catch (error) {
        console.log(error)
        if (typeof error === 'string' && error?.indexOf('rejection') !== -1) {
          this.$store.commit('showMessage', { text: 'Окно логина было закрыто', severity: 'warning' });
        } else {
          this.$store.commit('showMessage', { text: 'Не удалось залогиниться на waves.exchange.', severity: 'error' });
        }
        this.signer = null
      }
    },
    async massTransferWaves() {
      try {
        console.log(this.stackers.filter((item) => this.selected.includes(item)).map(item => ({
          amount: Math.round(item.benefit * 100000),
          recipient: item.address,
        })))
        const tx = await this.signer.massTransfer({
          transfers: this.stackers.filter((item) => this.selected.includes(item)).map(item => ({
            amount: Math.round(item.benefit * 100000),
            recipient: item.address,
          })),
          assetId: this.rusCoinAddress,
          attachment: crypto.base58Encode(crypto.stringToBytes('stacking percent for ' + this.dateText)),
        }).broadcast()

        console.log('tx:', tx)
        if (tx?.id) {
          this.transactionId = tx?.id
          this.$store.dispatch('setMonthTransactionData', { id: tx.sender, data: tx })
          this.showCheck = true
          const balances = await this.signer.getBalance()
          this.$store.commit('setBalances', balances)
        }
        this.signer.waitTxConfirm(tx, 1).then(async () => {
          this.confirmed = true
          this.$store.commit('showMessage', { text: 'Ваш перевод успешно записан в сети блокчейн.', severity: 'success' });
          const balances = await this.signer.getBalance()
          this.$store.commit('setBalances', balances)
          // await this.$store.dispatch('getRusCoinsBalance')
          // await this.$store.dispatch('getWavesBalance')
          // Tx have one confirmation
        })
      } catch (error) {
        console.log(typeof error, error?.message, error)
        if (typeof error === 'string' && error?.indexOf('rejection') !== -1) {
          this.$store.commit('showMessage', { text: 'Вы нажали "Reject", для подтвержения перевода надо нажать "Confirm".', severity: 'warning' });
        } else {
          this.$store.commit('showMessage', { text: 'Не удалось сделать перевод на waves.exchange.', severity: 'error' });
        }
      }
    },
    async returnTransferWaves(id, value) {
      try {
        const tx = await this.signer.transfer({
          // recipient: '3PApebH2ATkYEQEFiyYgM4XUndXg3VXWBJy', // alexey
          // recipient: '3PNCTdLy1X86E4WFQa8diMEcWsy1eokBcBg', // dendroid
          recipient: id,
          amount: value * 100000,
          assetId: this.rusCoinAddress,
          attachment: crypto.base58Encode(crypto.stringToBytes('stacking return transfer')),
        }).broadcast()

        console.log('tx:', tx)
        if (tx?.id) {
          this.transactionId = tx?.id
          this.$store.dispatch('setReturnTransactionData', { id: tx.id, data: tx })
          this.showCheck = true
          const balances = await this.signer.getBalance()
          this.$store.commit('setBalances', balances)
        }
        this.signer.waitTxConfirm(tx, 1).then(async () => {
          this.confirmed = true
          this.$store.commit('showMessage', { text: 'Ваш перевод успешно записан в сети блокчейн.', severity: 'success' });
          const balances = await this.signer.getBalance()
          this.$store.commit('setBalances', balances)
          // await this.$store.dispatch('getRusCoinsBalance')
          // await this.$store.dispatch('getWavesBalance')
          // Tx have one confirmation
        })
      } catch (error) {
        console.log(typeof error, error?.message, error)
        if (typeof error === 'string' && error?.indexOf('rejection') !== -1) {
          this.$store.commit('showMessage', { text: 'Вы нажали "Reject", для подтвержения перевода надо нажать "Confirm".', severity: 'warning' });
        } else {
          this.$store.commit('showMessage', { text: 'Не удалось сделать перевод на waves.exchange.', severity: 'error' });
        }
      }
    },
    async connectMetamask() {
      try {
        // Request account access
        await this.ethereum.enable();
        return true
      } catch(e) {
        // User denied access
        return false
      }
    },
    async payStakingBSC() {
      if (this.ethereum?.isMetaMask) {
        try {
          // const accounts = await this.ethereum.request({ method: 'eth_accounts' });
          const data = '0x47afcc82' + '0000000000000000000000000000000000000000000000000000000000000040' + to64(32 * (3 + this.selectedBSC.length)) +
            to64(this.selectedBSC.length) + this.selectedBSC.map(item => to64(item.address)).join('') + to64(this.selectedBSC.length) + this.selectedBSC.map(item => to64(item.benefit * 100000)).join('')
          // console.log(data)
          const pay = await this.ethereum.request({
            method: 'eth_sendTransaction',
            params: [{
              from: '0x532f26637ae7feb489e299c07d66da91d187e773',
              // from: '0x0000000000000000000000000000000000000000',
              data,
              to: '0xb5f8ec2e6190bc79e4a6d64168bd0ba406c31073'
            }]
          })
          console.log(pay)
          // 0x47afcc82000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000800000000000000000000000000000000000000000000000000000000000000001000000000000000000000000630c3415946c72fb6df78447cc6a0539691d4ccc000000000000000000000000000000000000000000000000000000000000000100000000000000000000000000000000000000000000000000000000000009d8
          // 0x47afcc82000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000002000000000000000000000000630c3415946c72fb6df78447cc6a0539691d4ccc000000000000000000000000630c3415946c72fb6df78447cc6a0539691d4ccc000000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000009d8000000000000000000000000000000000000000000000000000000000000012c
          // 0x47afcc82000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000a00000000000000000000000000000000000000000000000000000000000000002000000000000000000000000630C3415946c72Fb6dF78447Cc6a0539691D4ccc0000000000000000000000009CD38963E56fD92cdcDE2a2E71Ae947ee19f702e00000000000000000000000000000000000000000000000000000000000000020000000000000000000000000000000000000000000000000000000000033450000000000000000000000000000000000000000000000000000000000000c350
          // 0x47afcc82000000000000000000000000000000000000000000000000000000000000004000000000000000000000000000000000000000000000000000000000000000c00000000000000000000000000000000000000000000000000000000000000003000000000000000000000000630c3415946c72fb6df78447cc6a0539691d4ccc000000000000000000000000630c3415946c72fb6df78447cc6a0539691d4ccc000000000000000000000000630c3415946c72fb6df78447cc6a0539691d4ccc000000000000000000000000000000000000000000000000000000000000000300000000000000000000000000000000000000000000000000000000000009d8000000000000000000000000000000000000000000000000000000000000012c0000000000000000000000000000000000000000000000000000000000009c40

        } catch (error) {
          if (error.data?.message) {
            alert(error.data.message)
          }
          if (error.message) {
            alert(error.message)
          }
          console.log(error)
        }
      } else {
        alert('Connect metamask first!')
      }
    },
  },
  async mounted () {
    document.title = 'RusCoin Staking Table'
    if (localStorage.auth) {
      try {
        // await this.$store.dispatch('authEmail')
        // const arr = []
        // const data = await this.$store.dispatch('getTransactionData')
        // if (!data.empty) {
        //   data.forEach((doc) => {
        //     const value = doc.data()
        //     arr.push(value)
        //     console.log(`address: '${value.sender}', short: '${value.sender.substring(0,5)+'****'+value.sender.substring(30,35)}', value: ${value.amount/100000}, percent: ${this.calcPercent(value.amount/100000)}, days: 11, benefit: 10000 * 0.009 * 11/15, startDate: ${moment(value.timestamp).format('Do MMMM')}, endDate: moment(${value.timestamp} + 100*24*60*60*1000).format('Do MMMM'), id: '${value.id}'`)
        //   });
        // }
        // console.log(arr)
        // this.tx = arr
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<!--
fetch("https://nodes.waves.exchange/transactions/calculateFee", {
"headers": {
"accept": "*/*",
"accept-language": "en-US,en;q=0.9,ru;q=0.8,de;q=0.7",
"content-type": "application/json",
"sec-ch-ua": "\" Not;A Brand\";v=\"99\", \"Google Chrome\";v=\"91\", \"Chromium\";v=\"91\"",
"sec-ch-ua-mobile": "?0",
"sec-fetch-dest": "empty",
"sec-fetch-mode": "cors",
"sec-fetch-site": "same-site"
},
"referrer": "https://waves.exchange/",
"referrerPolicy": "origin",
"body": "{\"type\":11,\"version\":2,\"senderPublicKey\":\"7mmx9UZsby7Y983vURacF1Di6BWCkZe2ABYsRhhPBoc6\",\"assetId\":\"EMdiF8uaySswfCdMxc114rSfzUsAKCtK9d8eSx6ruKP4\",\"transfers\":[{\"recipient\":\"3PNCTdLy1X86E4WFQa8diMEcWsy1eokBcBg\",\"amount\":100000}],\"fee\":200000,\"timestamp\":1626372502173,\"attachment\":\"HXLk\",\"proofs\":[],\"chainId\":87,\"id\":\"FFhbqLX6SBfQibEfdSAeFR4H9erro4u4QsvpUBAQ2N82\"}",
"method": "POST",
"mode": "cors",
"credentials": "include"
});


fetch("https://nodes.waves.exchange/transactions/broadcast", {
"headers": {
"accept": "*/*",
"accept-language": "en-US,en;q=0.9,ru;q=0.8,de;q=0.7",
"content-type": "application/json",
"sec-ch-ua": "\" Not;A Brand\";v=\"99\", \"Google Chrome\";v=\"91\", \"Chromium\";v=\"91\"",
"sec-ch-ua-mobile": "?0",
"sec-fetch-dest": "empty",
"sec-fetch-mode": "cors",
"sec-fetch-site": "cross-site"
},
"referrer": "https://vlzhr.github.io/",
"referrerPolicy": "strict-origin-when-cross-origin",
"body": "{\"type\":11,\"version\":2,\"senderPublicKey\":\"7mmx9UZsby7Y983vURacF1Di6BWCkZe2ABYsRhhPBoc6\",\"assetId\":\"EMdiF8uaySswfCdMxc114rSfzUsAKCtK9d8eSx6ruKP4\",\"transfers\":[{\"recipient\":\"3PNCTdLy1X86E4WFQa8diMEcWsy1eokBcBg\",\"amount\":100000}],\"fee\":200000,\"timestamp\":1626372502173,\"attachment\":\"HXLk\",\"proofs\":[\"4WpardTBohMFUzjBLgz9zYsgee1HkQ751NNHB5LDxs6xWzcQmRpvesu4DEapDfhAqdPqxQUsxdkxadj4E24fDPMC\"],\"chainId\":87,\"id\":\"FFhbqLX6SBfQibEfdSAeFR4H9erro4u4QsvpUBAQ2N82\"}",
"method": "POST",
"mode": "cors",
"credentials": "include"
});
-->

<style lang="scss">
.tableCard {
  max-width: 100%;
  width: 940px;
  margin: 20px auto;
  padding: 32px;
  background-color: rgba(250,250,250,0.05) !important;
  backdrop-filter: blur(16px);
  @media (max-width: 780px) {
    margin: 10px 5px;
    padding: 5px 0;
  }
  @supports not (backdrop-filter: none) {
    background-color: rgba(39,45,60,0.8) !important;
  }
}
</style>
