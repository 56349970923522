<template>
  <div class="d-flex flex-column my-5" style="width: 100vw" v-if="$t('home.title')!=='home.title'">

    <!--stake Metamask-->
<!--    <v-card class="bscCard" v-if="metamaskRusCoinAdded || rusCoinsStakingBalance || rusCoinsBalance">
      <v-card-title>{{$t('bsc.stakingTitle')}}</v-card-title>
      <v-card-text v-html="$t('bsc.stakingText')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" v-if="rusCoinsBalance === 0 && rusCoinsStakingBalance > 0">
        {{$t('bsc.stakingDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!isMetamask || !isBsc || rusCoinsBalance >= 2" @click="stakingMetamask()" v-else>
        {{$t('bsc.stakingButton')}}
      </v-btn>
    </v-card>-->

    <div class="bscHeader">
      <h2>{{$t('bsc.title')}}</h2>
      <p class="mb-1" v-if="stepsCompleted">{{$t('bsc.subtitle').replace('0', stepsCompleted)}}</p>
    </div>

    <!--install Metamask-->
    <v-card class="bscCard">
      <v-card-title>{{$t('bsc.installTitle')}}</v-card-title>
      <v-card-text>
        {{$t('bsc.installText')}}
      </v-card-text>
      <v-card-text>
        {{$t('bsc.installText2')}}
      </v-card-text>
      <v-card-text>
        {{$t('bsc.installText3')}}
      </v-card-text>
      <div class="iframe-container" v-if="!isMetamask">
        <iframe width="100%" src="https://www.youtube.com/embed/YVgfHZMFFFQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" v-if="isMetamask">
        {{$t('bsc.installDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" @click="openWindow('https://metamask.io/download'); reloadButton=true" v-else-if="!reloadButton">
        {{$t('bsc.installButton')}}
      </v-btn>
      <div class="mb-3" v-else-if="innerWidth < 1024">
        <v-card-text v-html="$t('bsc.installText4')"></v-card-text>
        <v-img class="my-3" src="/img/common/metamaskInstr.png"></v-img>
        <v-card-text v-html="$t('bsc.installText5')"></v-card-text>
      </div>
      <v-btn dark color="red darken-3" class="my-2 ml-3" @click="reloadWindow()" v-else>
        {{$t('bsc.installReloadButton')}}
      </v-btn>
    </v-card>

    <!--connect Metamask-->
    <v-card class="bscCard">
      <v-card-title>{{$t('bsc.connectTitle')}}</v-card-title>
      <v-card-text v-html="$t('bsc.connectText')">
      </v-card-text>
      <v-card-text v-html="$t('bsc.connectText2')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" v-if="accounts.length">
        {{$t('bsc.connectDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!isMetamask" @click="connectMetamask()" v-else>
        {{$t('bsc.connectButton')}}
      </v-btn>
    </v-card>

    <!--add BSC to Metamask-->
    <v-card class="bscCard">
      <v-card-title>{{$t('bsc.addBSCTitle')}}</v-card-title>
      <v-card-text>
        {{$t('bsc.addBSCText')}}
      </v-card-text>
      <v-card-text v-html="$t('bsc.addBSCText2')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" @click="metamaskAddBSC()" v-if="isBsc">
        {{$t('bsc.addBSCDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!isMetamask" @click="metamaskAddBSC()" v-else>
        {{$t('bsc.addBSCButton')}}
      </v-btn>
    </v-card>

    <!--add RusCoin to Metamask-->
    <v-card class="bscCard">
      <v-card-title>{{$t('bsc.addRSCTitle')}}</v-card-title>
      <v-card-text v-html="$t('bsc.addRSCText')">
      </v-card-text>
      <v-card-text v-html="$t('bsc.addRSCText2')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" @click="metamaskAddRSC()" v-if="metamaskRusCoinAdded">
        {{$t('bsc.addRSCDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!isMetamask || !isBsc" @click="metamaskAddRSC()" v-else>
        {{$t('bsc.addRSCButton')}}
      </v-btn>
    </v-card>

    <!--buy BNB-->
    <v-card class="bscCard">
      <v-card-title>{{$t('bsc.buyBNBTitle')}}</v-card-title>
      <v-card-text v-html="$t('bsc.buyBNBText').replace('0', bnbBalance)">
      </v-card-text>
      <v-card-text v-html="$t('bsc.buyBNBText2')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" @click="openWindow('https://accounts.binance.com/ru/register?ref=17172123');" v-if="metamaskBnbBought">
        {{$t('bsc.buyBNBDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!accounts.length || !isMetamask || !isBsc" @click="openWindow('https://accounts.binance.com/ru/register?ref=17172123');" v-else>
        {{$t('bsc.buyBNBButton')}}
      </v-btn>
    </v-card>

    <!--send BNB-->
    <v-card class="bscCard">
      <v-card-title>{{$t('bsc.sendBNBTitle')}}</v-card-title>
      <v-card-text v-html="$t('bsc.sendBNBText')">
      </v-card-text>
      <v-card-text v-html="$t('bsc.sendBNBText2')">
      </v-card-text>
      <v-alert type="success" class="my-2 ml-3 py-3 px-5 alert-done" @click="metamaskSendBnb()" v-if="metamaskRusCoinBought">
        {{$t('bsc.sendBNBDone')}}
      </v-alert>
      <v-btn dark color="orange darken-3" class="my-2 ml-3" :disabled="!accounts.length || !isMetamask || !isBsc" @click="metamaskSendBnb()" v-else>
        {{$t('bsc.sendBNBButton')}}
      </v-btn>
    </v-card>

<!--    <v-dialog persistent v-model="showCheck" maxWidth="520" class="calculator" overlay-opacity="0.7">
    </v-dialog>-->
  </div>
</template>

<script>

export default {
  name: 'BinanceSteps',
  components: { },
  data: () => ({
    loading: false,
    reloadButton: false,
    hideRusCoinButton: false,
  }),
  computed: {
    // METAMASK
    innerWidth () {return window.innerWidth},
    ethereum () {return window.ethereum},
    isMetamask () {return this.ethereum?.isMetaMask},
    isBsc () {return +this.$store.state.metamaskChainId === 56},
    isLoading () {return this.$store.state.metamaskChainId === 0},
    accounts () {return this.$store.state.metamaskAccounts},
    bnbBalance () {return this.$store.state.metamaskBalanceLast},
    rusCoinsBalance () {return this.$store.state.metamaskRusCoinBalanceLast},
    rusCoinsStakingBalance () {return this.$store.state.metamaskRusCoinStakingBalanceLast},
    metamaskRusCoinAdded () {return this.$store.state.metamaskRusCoinAdded},
    metamaskBnbBought () {return this.$store.state.metamaskBnbBought},
    metamaskRusCoinBought () {return this.$store.state.metamaskRusCoinBought},
    canMetamask () {return navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("Firefox") !== -1 || navigator.userAgent.indexOf("Brave") !== -1 || navigator.userAgent.indexOf("Edge") !== -1},
    stepsCompleted () {return (this.isMetamask) + (this.accounts.length > 0) + (this.isBsc) + (this.metamaskRusCoinAdded) + (this.metamaskBnbBought) + (this.metamaskRusCoinBought)},
  },
  methods: {
    openWindow (url) {
      window.open(url, 'transaction')
    },
    reloadWindow () {
      window.location.reload()
    },
    async connectMetamask() {
      try {
        // Request account access
        await this.ethereum.enable();
        return true
      } catch(e) {
        // User denied access
        return false
      }
    },
    async metamaskAddBSC () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      const params = [{
        "chainId": "0x38", // 56 in decimal
        "chainName": "Binance Smart Chain",
        "rpcUrls": [
          "https://bsc-dataseed.binance.org"
        ],
        "nativeCurrency": {
          "name": "Binance Coin",
          "symbol": "BNB",
          "decimals": 18
        },
        "blockExplorerUrls": [
          "https://bscscan.com"
        ]
      }]

      try {
        // console.log(params)
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params,
        })
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskAddRSC () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: '0xb5F8eC2e6190Bc79e4a6D64168Bd0BA406c31073', // The address that the token is at.
              symbol: 'RSC', // A ticker symbol or shorthand, up to 5 chars.
              decimals: 5, // The number of decimals in the token
              image: 'https://ruscoin.vip/img/icons/android-chrome-192x192.png', // A string url of the token logo
            },
          },
        });
        // console.log(wasAdded, this.metamaskRusCoinAdded)
        if (wasAdded) {
          // this.hideRusCoinButton = true
          this.$store.commit('setMetamaskRusCoinAdded', true);
        }
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskSendBnb () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        const wasSent = await window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [{
            nonce: '0x00', // ignored by MetaMask
            gasPrice: '12a05f200', // 5 gwei.
            gas: '0xc350', // customizable by user during MetaMask confirmation.
            to: '0xb5F8eC2e6190Bc79e4a6D64168Bd0BA406c31073', // Required except during contract publications.
            from: this.accounts[0], // must match user's active address.
            value: '0x2c68af0bb140000', // Only required to send ether to the recipient from the initiating external account.
            chainId: '0x38',
          }],
        });
        if (wasSent) {
          this.hideRusCoinButton = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async metamaskSendRsc () {
      if (!window.ethereum?.isMetaMask) {
        this.$store.commit('showMessage', { text: this.$t('bsc.noMetaMask'), severity: 'error' })
        return
      }
      try {
        console.log(111)
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted () {
    document.title = 'RusCoin + 6 steps to buy'
  }
}
</script><style lang="scss">
.bscHeader {
  max-width: 780px;
  margin: 4px auto;
  background: transparent;
  text-align: center;
  @media (max-width: 780px) {
    margin: 10px 5px;
    padding: 5px 0;
  }
}
.bscCard {
  max-width: 780px;
  margin: 20px auto;
  padding: 32px;
  background-color: rgba(250,250,250,0.05) !important;
  backdrop-filter: blur(16px);
  @media (max-width: 780px) {
    margin: 10px 5px;
    padding: 5px 0;
  }
  @supports not (backdrop-filter: none) {
    background-color: rgba(39,45,60,0.8) !important;
  }
  .alert-done {
    width: fit-content;
  }
  .iframe-container {
    margin: 16px 16px 32px;
    position: relative;
    //width: calc(100% - 32px);
    padding-bottom: 56.25%;
    height: 0;
  }
  .iframe-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
